export {
  LandingHero,
  OurServices,
  WhyChooseUs,
  HeroSlider,
  OurIndustries,
  OurStandardPractices,
  Testimonial,
  LetsConnect,
  OurOffering,
  RNP,
} from './LandingComponents';

export {
  ServiceHero,
  ServiceTab,
  TechnologyStack,
  ServicesRows,
} from './ServiceComponents';
export {
  TechnologyDescription,
  TechnologyHeading,
  AdditionalTechnologyDescription,
  FAQ,
} from './DevelopmentTools';
export {
  OurValues,
  CareerHero,
  SearchJobs,
  CounterContainer,
  RecruitmentProcess,
  BenefitsAndPackage,
} from './CareerComponents';

export {
  AboutusHero,
  MissionAndVison,
  MarqueeSlider,
} from './AboutusComponent';

export { JobDetails } from './JobDetails';
export { PrivacyPolicy } from './PrivacyPolicy';
export { TermsAndCondition } from './TermsAndCondition';
export { BlogCard } from './Blog';
