import React from 'react';
import './TechnologyDescription.css';

const TechnologyDescription = props => {
  const technologyStyle = {
    background: 'url(' + props.bgImg + ')',
    backgroundRepeat: 'no-repeat no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  };
  return (
    <>
      <div
        style={technologyStyle}
        className='relative   w-full flex h-[800px] justify-center '
      >
        <div className=' w-[80%] md:w-[60%] z-20 flex flex-col items-center mt-16'>
          <div className=' text-2xl md:text-[40px] font-extrabold uppercase text-[#FF6C14]'>
            {props.first_title}
          </div>
          <div
            className='mt-4'
            dangerouslySetInnerHTML={{
              __html: props.description,
            }}
            style={{ fontSize: '16px', fontWeight: 500 }}
          />
        </div>
      </div>
    </>
  );
};

export default TechnologyDescription;
