import React from 'react';
import { Link } from 'react-router-dom';
import './CareerLink.css';

const CareerLink = () => {
  return (
    <>
      <div className='CareerLink'>
        <div className='CareerLink_wrapper'>
          <div className='CareerLink_heading'>We're Hiring!</div>

          <div className='CareerLink_sub_heading'>
            Our Team is growing fast and we’re always looking for smart people!
          </div>

          <div className='career_link'>
            <Link to='/career'>
              <div className='hero-contact-btn'>View Openings &#8594;</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerLink;
