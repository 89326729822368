import React, { useState, useEffect } from 'react';
import Jobscard from './Jobscard/Jobscard';
import './SearchJobs.css';

import { JobsList } from '../../../../Storage/JobsList';
import axios from 'axios';

const SearchJobs = () => {
  const [jobsData, setJobData] = useState(JobsList);

  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/vacancy`).then(res => {
      // axios.get(`http://localhost:3000/v1/vacancy`).then(res => {
      setJobData(res.data);
    });
  }, []);
  return (
    <>
      <div className='SearchJobs_career'>
        <div className='SearchJobs_career_wrapper container'>
          <div className='SearchJobs_career_job_opening_card'>
            <div className='SearchJobs_career_heding_and_available_jobs'>
              <div className='SearchJobs_career_heding'>Job Opening</div>

              <div className='SearchJobs_career_available_jobs'>
                {jobsData && jobsData.length} Jobs Available
              </div>
            </div>
          </div>

          <div classNameName='search_jobs_available_jobs'>
            <div className='ant-row'>
              {jobsData && jobsData.length >= 1 ? (
                <>
                  {jobsData &&
                    jobsData.map((e, index) => (
                      <div
                        className='ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-8 ant-col-xl-8'
                        key={index}
                      >
                        <Jobscard
                          title={e.title}
                          location={'Radhe Radhe, Bhaktapur'}
                          time={e.date}
                          link={`/career/${e.title
                            .replace(/[\W]/g, '-')
                            .toLowerCase()}`}
                        />
                      </div>
                    ))}
                </>
              ) : (
                <>
                  {jobsData &&
                    jobsData
                      .filter(filterData => filterData.title === selectedJob)

                      .map((e, index) => (
                        <div
                          className='ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-8 ant-col-xl-8'
                          key={index}
                        >
                          <Jobscard
                            title={e.title}
                            location={'Radhe Radhe, Bhaktapur'}
                            time={e.date}
                            link={`/career/${e.title}`}
                          />
                        </div>
                      ))}
                </>
              )}
            </div>

            <div className='hero_container_buttons'>
              {/* <Link to='/'>
                <div className='hero-contact-btn'>View All Openings</div>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchJobs;
