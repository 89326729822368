import React from 'react';
import './ContactPageHeaderImg.css';
import Lottie from 'react-lottie';
import ServiceSVG from '../../../../assets/Services/contact.json';
import { Divider } from 'antd';

const ContactPageHeaderImg = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ServiceSVG,
    renderer: 'svg',
  };

  return (
    <>
      <div className='ContactPageHeaderImg'>
        <div className='container'>
          <div className='ant-row'>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='contact_heading_text '>
                <h1>Contact </h1>

                <Divider />

                <p>
                  We're just one click away to help you take your brand or
                  product from great to incredible.{' '}
                </p>
              </div>
            </div>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <Lottie options={defaultOptions} height={400} width={'100%'} />
            </div>
          </div>
        </div>
        {/* <img src={ContactPageBg} alt='' /> */}
      </div>
    </>
  );
};

export default ContactPageHeaderImg;
