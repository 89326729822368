import React from 'react';
import {
  HeroSlider,
  OurServices,
  OurIndustries,
  OurStandardPractices,
  OurOffering,
  CounterContainer,
} from '../../components/UI';
import { Helmet } from 'react-helmet-async';
import {
  RNP,
  TechnologyWeWorkWith,
} from '../../components/UI/LandingComponents';
import HomePageBlog from '../../components/UI/Blog/BlogCard/HomePageBlog';

// import { Skeleton } from 'antd';

const Home = () => {
  return (
    <>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title data-react-helmet='true'>
          AITC International | Web, App & Software Development Company
        </title>
        <meta
          data-react-helmet='true'
          name='description'
          content=' AITC International provides a wide range of IT services including web design, custom software development, digital marketing, mobile app development, and more. Contact us today to discuss your project! '
        />
        <link rel='canonical' href='https://aitc.ai' />

        <meta property='og:type' content='website' data-react-helmet='true' />
        <meta property='og:url' content='https://aitc.ai' />
        <meta
          data-react-helmet='true'
          property='og:title'
          content='AITC International | Web, App & Software Development Company'
        />
        <meta
          data-react-helmet='true'
          property='og:description'
          content=' AITC International provides a wide range of IT services including web design, custom software development, digital marketing, mobile app development, and more. Contact us today to discuss your project! '
        />
        <meta
          data-react-helmet='true'
          property='og:image'
          content='https://d1q13i93c33suk.cloudfront.net/aitcsocialog.jpg'
        />
        {/* <!-- Twitter --> */}
        <meta
          data-react-helmet='true'
          property='twitter:card'
          content='summary_large_image'
        />
        <meta
          data-react-helmet='true'
          property='twitter:url'
          content='https://aitc.ai'
        />
        <meta
          data-react-helmet='true'
          property='twitter:title'
          content='AITC International | Web, App & Software Development Company'
        />
        <meta
          property='twitter:description'
          content=' AITC International provides a wide range of IT services including web design, custom software development, digital marketing, mobile app development, and more. Contact us today to discuss your project! '
        />
        <meta
          property='twitter:image'
          content='https://d1q13i93c33suk.cloudfront.net/aitcsocialog.jpg'
        />
      </Helmet>
      <HeroSlider />
      {/* <LandingHero /> */}
      {/* <Skeleton avatar paragraph={{ rows: 2 }} active /> */}
      <OurServices />

      {/* <WhyChooseUs /> */}
      <OurStandardPractices />
      <TechnologyWeWorkWith />
      <OurIndustries />
      <OurOffering />
      <HomePageBlog />
      <CounterContainer />
      <RNP />
      {/* <Testimonial /> */}
      {/* <LetsConnect /> */}
    </>
  );
};

export default Home;
