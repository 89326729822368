import React, { useState, useEffect } from 'react';
import { Divider, Tabs, Typography, Card } from 'antd';
import './TechnologyWeWorkWith.css';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const TechnologyWeWorkWith = () => {
  const [data, setData] = useState(null);
  const api_url = `${process.env.REACT_APP_API_KEY}/`;

  useEffect(() => {
    // axios.get(`${process.env.REACT_APP_API_KEY}/stack`).then(res => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/technologies-and-platforms`)
      .then(res => {
        setData(res.data.data);
      });
  }, []);

  const { TabPane } = Tabs;

  const { Title } = Typography;
  return (
    <div>
      {data && data.length > 0 ? (
        <div id='TechnologyWeWorkWith'>
          <div className='TechnologyWeWorkWith_wrapper container'>
            <div className='Consultation_heading'>
              Technologies and Platforms We Work With
            </div>

            <div className='heading-divider'>
              <Divider />
            </div>

            <div className='offering_tab'>
              <Tabs defaultActiveKey='1' tabPosition={'left'} animated={false}>
                {data &&
                  data.map((e, index) => (
                    <TabPane tab={e.techTitle} key={index}>
                      <div className='offering_tab_content'>
                        <div className='offering_tab_content_heading'>
                          <Title
                            level={3}
                            style={{
                              marginTop: '0px',
                              marginBottom: '10px',
                              fontWeight: 'bold',
                            }}
                          >
                            {e.techTitle}
                          </Title>
                        </div>

                        <div className='offering_tab_content_text'>
                          <div className='ant-row'>
                            {e.tech_with_image.map((a, index) => (
                              <div
                                className='ant-col ant-col-xs-12 ant-col-sm-8  ant-col-md-6 ant-col-lg-4 ant-col-xl-4 '
                                key={index}
                              >
                                <img
                                  src={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${a.image}`}
                                  alt={a.technology}
                                />
                              </div>
                            ))}
                          </div>
                          {/* <Divider /> */}
                          {/* <span>FRONT END</span>
                        <div className='ant-row'>
                          <div className='ant-col ant-col-xs-24 ant-col-sm-8  ant-col-md-6 ant-col-lg-4 ant-col-xl-4 '>
                            <img src={Reactjs} alt='' />
                          </div>
                        </div> */}
                          <br />
                        </div>
                      </div>
                    </TabPane>
                  ))}

                {/* <TabPane tab='Web' key='1'>
                <div className='offering_tab_content'>
                  <div className='offering_tab_content_heading'>
                    <Title
                      level={3}
                      style={{
                        marginTop: '0px',
                        marginBottom: '10px',
                        fontWeight: 'bold',
                      }}
                    >
                      {' '}
                      Web
                    </Title>
                  </div>

                  <div className='offering_tab_content_text'>
                    <span>BACK END</span>
                    <div className='ant-row'>
                      <div className='ant-col ant-col-xs-12 ant-col-sm-8  ant-col-md-6 ant-col-lg-4 ant-col-xl-4 '>
                        <img src={Reactjs} alt='' />
                      </div>
                      <div className='ant-col ant-col-xs-12 ant-col-sm-8  ant-col-md-6 ant-col-lg-4 ant-col-xl-4 '>
                        <img src={Reactjs} alt='' />
                      </div>
                      <div className='ant-col ant-col-xs-12 ant-col-sm-8  ant-col-md-6 ant-col-lg-4 ant-col-xl-4 '>
                        <img src={Reactjs} alt='' />
                      </div>
                      <div className='ant-col ant-col-xs-12 ant-col-sm-8  ant-col-md-6 ant-col-lg-4 ant-col-xl-4 '>
                        <img src={Reactjs} alt='' />
                      </div>
                    </div>
                    <Divider />
                    <span>FRONT END</span>
                    <div className='ant-row'>
                      <div className='ant-col ant-col-xs-24 ant-col-sm-8  ant-col-md-6 ant-col-lg-4 ant-col-xl-4 '>
                        <img src={Reactjs} alt='' />
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </TabPane> */}

                {/* <TabPane tab='Mobile' key='2'>
                Content of Tab Pane 2
              </TabPane>
              <TabPane tab='Desktop' key='3'>
                Content of Tab Pane 2
              </TabPane>
              <TabPane tab='Platforms' key='4'>
                Content of Tab Pane 2
              </TabPane>
              <TabPane tab='Data storages' key='5'>
                Content of Tab Pane 2
              </TabPane>
              <TabPane tab='DevOps' key='6'>
                Content of Tab Pane 2
              </TabPane>
              <TabPane tab='Clouds' key='7'>
                Content of Tab Pane 2
              </TabPane> */}
              </Tabs>

              <div className='offering_tab_for_mobile'>
                <Swiper
                  pagination={true}
                  modules={[Pagination]}
                  className='mySwiper'
                  spaceBetween={20}
                >
                  {data &&
                    data.map((e, index) => (
                      <SwiperSlide key={index}>
                        <Card>
                          <div className='offering_tab_card_for_mobile'>
                            <div className='offering_tab_card_heading_for_mobile'>
                              <h3>{e.techTitle}</h3>
                              <Divider />
                            </div>

                            <div className='offering_tab_content_text'>
                              <div className='ant-row'>
                                {e.tech_with_image.map((a, index) => (
                                  <div
                                    className='ant-col ant-col-xs-6 ant-col-sm-6  ant-col-md-6 ant-col-lg-4 ant-col-xl-4 '
                                    key={index}
                                  >
                                    <img
                                      src={`${api_url}${a.image}`}
                                      alt={a.technology}
                                      height='65px'
                                    />
                                  </div>
                                ))}
                              </div>

                              {/* <span>FRONT END</span>
                        <div className='ant-row'>
                          <div className='ant-col ant-col-xs-24 ant-col-sm-8  ant-col-md-6 ant-col-lg-4 ant-col-xl-4 '>
                            <img src={Reactjs} alt='' />
                          </div>
                        </div> */}
                              <br />
                            </div>
                          </div>
                        </Card>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default TechnologyWeWorkWith;
