// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/aboutus/career-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CareerLink {\n  padding: 40px 0;\n}\n\n.CareerLink_wrapper {\n  text-align: center;\n\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat no-repeat !important;\n  background-position: left;\n  background-size: contain !important;\n}\n\n.CareerLink_heading {\n  font-weight: 700;\n  font-size: 26px;\n}\n\n.CareerLink_sub_heading {\n  font-weight: 400;\n  font-size: 26px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI/AboutusComponent/CareerLink/CareerLink.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;;EAElB,mDAA2D;EAC3D,iDAAiD;EACjD,yBAAyB;EACzB,mCAAmC;AACrC;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".CareerLink {\n  padding: 40px 0;\n}\n\n.CareerLink_wrapper {\n  text-align: center;\n\n  background: url('../../../../assets/aboutus/career-bg.png');\n  background-repeat: no-repeat no-repeat !important;\n  background-position: left;\n  background-size: contain !important;\n}\n\n.CareerLink_heading {\n  font-weight: 700;\n  font-size: 26px;\n}\n\n.CareerLink_sub_heading {\n  font-weight: 400;\n  font-size: 26px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
