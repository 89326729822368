// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/Career/career-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CareerHero {\n  padding: 80px 0;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat no-repeat !important;\n  background-position: center center !important;\n  /* background-size: cover !important; */\n}\n\n.CareerHero .CareerHero_banner_img img {\n  max-height: 400px;\n  max-width: 400px;\n  width: 100%;\n}\n\n.CareerHero .CareerHero_banner_img {\n  text-align: center;\n  margin-top: 30px;\n}\n\n.CareerHero .ant-row {\n  align-items: center;\n}\n\n.CareerHero .hero_container_buttons {\n  margin-top: 30px;\n}\n\n.CareerHero_wrapper {\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI/CareerComponents/CareerHero/CareerHero.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,yDAAwE;EACxE,iDAAiD;EACjD,6CAA6C;EAC7C,uCAAuC;AACzC;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;AACA","sourcesContent":[".CareerHero {\n  padding: 80px 0;\n  background-image: url('../../../../assets/Career/career-background.png');\n  background-repeat: no-repeat no-repeat !important;\n  background-position: center center !important;\n  /* background-size: cover !important; */\n}\n\n.CareerHero .CareerHero_banner_img img {\n  max-height: 400px;\n  max-width: 400px;\n  width: 100%;\n}\n\n.CareerHero .CareerHero_banner_img {\n  text-align: center;\n  margin-top: 30px;\n}\n\n.CareerHero .ant-row {\n  align-items: center;\n}\n\n.CareerHero .hero_container_buttons {\n  margin-top: 30px;\n}\n\n.CareerHero_wrapper {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
