import React from 'react';
import { Typography } from 'antd';
import './LandingHero.css';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

const { Title } = Typography;
const LandingHero = props => {
  return (
    <>
      <div id='Hero_container'>
        <div className='hero_container_wrapper container'>
          <div className='ant-row' style={{ alignItems: 'center' }}>
            <Fade bottom cascade>
              <div
                className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'
                style={{ margin: '30px 0' }}
              >
                <div className='hero_container_heading'>
                  <h2
                    style={{ fontFamily: " 'Barlow', sans-serif !important" }}
                    className='text-[48px] text-[#000000D9] font-semibold'
                  >
                    {props.sliderTitle} <br />
                  </h2>
                </div>
                <div className='hero_container_sub-heading'>
                  <h4
                    style={{ fontFamily: " 'Barlow', sans-serif !important" }}
                    className='text-base text-[#000000D9] font-semibold'
                  >
                    {/* Our mission is to make your business better through
                    technology. */}
                    {props.sliderSubTitle}
                  </h4>
                </div>

                <div className='hero_container_buttons'>
                  <Link to='/contact'>
                    <div className='hero-contact-btn'>Let's connect</div>
                  </Link>

                  <Link to='/services'>
                    <div className='hero-our-work-btn'>Our Services</div>
                  </Link>
                </div>
              </div>
            </Fade>

            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div id='hero_svg'>
                {/* <Lottie options={defaultOptions} height={550} width={'100%'} /> */}

                <lottie-player
                  autoplay
                  loop
                  mode='normal'
                  src={props.HeroIllustration}
                  style={{ height: '550px' }}
                ></lottie-player>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingHero;
