import React, { useState, useRef, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import './CounterContainer.css';
import CountUp from 'react-countup';
import axios from 'axios';

const CounterContainer = () => {
  // const [memberCounter, setMemberCounter] = useState(0);
  // const [projectsCounter, setProjectsCounter] = useState(0);
  // const [hoursCounter, setHoursCounter] = useState(0);

  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/achivements`).then(res => {
      setData(res.data[0]);
    });
  }, []);

  const elementRef = useRef();

  return (
    <>
      <Fade cascade top>
        <div className='CounterContainer' ref={elementRef}>
          <div className='CounterContainer_wrapper container'>
            <div className='ant-row'>
              <div className='ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                <div className='couner_number'>
                  <CountUp
                    start={0}
                    end={data?.total_member}
                    duration={3}
                    delay={0}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} /> +
                      </div>
                    )}
                  </CountUp>
                </div>

                <div className='couner_text'>Team Member</div>
              </div>
              <div className='ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                <div className='couner_number'>
                  <CountUp
                    start={0}
                    end={data?.no_of_projects_completed}
                    duration={3}
                    delay={0}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} /> +
                      </div>
                    )}
                  </CountUp>
                </div>
                <div className='couner_text'>Projects Completed</div>
              </div>
              <div className='ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                <div className='couner_number'>
                  <CountUp
                    start={0}
                    end={data?.hours_of_work}
                    duration={4}
                    delay={0}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} /> +
                      </div>
                    )}
                  </CountUp>
                </div>
                <div className='couner_text'>Hours of Work</div>
              </div>
              <div className='ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                <div className='couner_number'>
                  <CountUp
                    start={0}
                    end={data?.happy_clients}
                    duration={3}
                    delay={0}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} /> +
                      </div>
                    )}
                  </CountUp>
                </div>
                <div className='couner_text'>Happy Clients</div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default CounterContainer;
