export { LandingHero } from './LandingPage';
export { OurServices } from './OurServices';
export { WhyChooseUs } from './WhyChooseUs';
export { HeroSlider } from './HeroSlider';
export { OurStandardPractices } from './OurStandardPractices';
export { OurIndustries } from './OurIndustries';
export { Testimonial } from './Testimonial';
export { LetsConnect } from './LetsConnect';
export { OurOffering } from './OurOffering';
export { TechnologyWeWorkWith } from './TechnologyWeWorkWith';
export { RNP } from './RNP';
