import React from 'react';
import './RecruitmentProcess.css';
import CV from '../../../../assets/Career/cv.json';
import Goals from '../../../../assets/Career/status_selection.json';
import Interview from '../../../../assets/Career/interview.json';
import Phone from '../../../../assets/Career/completed.json';
import Lottie from 'react-lottie';

import FirstLine from '../../../../assets/Career/steps/Vector.svg';
import SecondLine from '../../../../assets/Career/steps/Vector-right.svg';

const RecruitmentProcess = () => {
  const Lottie_CV = {
    loop: true,
    autoplay: true,
    animationData: CV,
    renderer: 'svg',
  };

  const Lottie_Goals = {
    loop: true,
    autoplay: true,
    animationData: Goals,
    renderer: 'svg',
  };

  const Lottie_Interview = {
    loop: true,
    autoplay: true,
    animationData: Interview,
    renderer: 'svg',
  };

  const Lottie_Phone = {
    loop: true,
    autoplay: true,
    animationData: Phone,
    renderer: 'svg',
  };

  return (
    <>
      <div className='RecruitmentProcess'>
        <div className='RecruitmentProcess_wrapper container'>
          <div className='RecruitmentProcess_heading'>
            Learn Our{' '}
            <span style={{ color: 'var(--primary-color)' }}>Recruitment </span>
            Process
          </div>
          <div className='RecruitmentProcess_cards'>
            <div className='interview_process_for_mobile'>
              <div className='ant-row'>
                <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-lg-8 ant-col-xl-6'>
                  <div className='RecruitmentProcess_card'>
                    <div className='RecruitmentProcess_card_wrapper'>
                      <div className='RecruitmentProcess_card_icon'>
                        <Lottie options={Lottie_CV} height={70} width={70} />
                      </div>
                      <div className='RecruitmentProcess_card_title'>
                        Submit Your CV
                      </div>
                      <div className='RecruitmentProcess_card_text'>
                        We will look into your CV and your work profile. Make
                        sure to send us an updated CV
                      </div>

                      <div className='step_count'>
                        <div className='step_count_number'>1</div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ---------------- */}

                <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-lg-8 ant-col-xl-6'>
                  <div className='RecruitmentProcess_card'>
                    <div className='RecruitmentProcess_card_wrapper'>
                      <div className='RecruitmentProcess_card_icon'>
                        <Lottie options={Lottie_Goals} height={70} width={70} />
                      </div>
                      <div className='RecruitmentProcess_card_title'>
                        Phone Interview
                      </div>
                      <div className='RecruitmentProcess_card_text'>
                        We will have a telephonic interview and get to know
                        about you and your work profile.
                      </div>
                      <div className='step_count'>
                        <div className='step_count_number'>2</div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ---------------- */}

                <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-lg-8 ant-col-xl-6'>
                  <div className='RecruitmentProcess_card'>
                    <div className='RecruitmentProcess_card_wrapper'>
                      <div className='RecruitmentProcess_card_icon'>
                        <Lottie
                          options={Lottie_Interview}
                          height={70}
                          width={70}
                        />
                      </div>
                      <div className='RecruitmentProcess_card_title'>
                        Interview Schedule
                      </div>
                      <div className='RecruitmentProcess_card_text'>
                        We will invite you for an interview depending upon the
                        nature and expertise of the job role.
                      </div>
                      <div className='step_count'>
                        <div className='step_count_number'>3</div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ---------------- */}

                <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-lg-8 ant-col-xl-6'>
                  <div className='RecruitmentProcess_card'>
                    <div className='RecruitmentProcess_card_wrapper'>
                      <div className='RecruitmentProcess_card_icon'>
                        <Lottie options={Lottie_Phone} height={70} width={70} />
                      </div>
                      <div className='RecruitmentProcess_card_title'>
                        Selection Status
                      </div>
                      <div className='RecruitmentProcess_card_text'>
                        After the final interview we will let you know whether
                        you have been selected or not.
                      </div>
                      <div className='step_count'>
                        <div className='step_count_number'>4</div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ---------------- */}
              </div>
            </div>

            <div className='interview_process_for_desktop'>
              <div className='ant-row'>
                <div className='interview_process_container'>
                  <div className='RecruitmentProcess_card'>
                    <div className='RecruitmentProcess_card_wrapper'>
                      <div className='RecruitmentProcess_card_title'>
                        Submit Your CV
                      </div>
                      <div className='RecruitmentProcess_card_text'>
                        We will look into your CV and your work profile. Make
                        sure to send us an updated CV
                      </div>
                    </div>

                    <div className='horizontal_line'>
                      <div className='horizontal_line_inner'>
                        <hr />
                      </div>
                    </div>

                    <div className='step_count'>
                      <div className='step_count_number'>1</div>
                    </div>

                    <div className='step_arrow_line'>
                      <img src={FirstLine} alt='' />
                    </div>

                    <div className='RecruitmentProcess_card_icon'>
                      <Lottie options={Lottie_CV} height={150} width={150} />
                    </div>
                  </div>
                </div>
                {/* ---- */}

                <div className='interview_process_container'>
                  <div className='RecruitmentProcess_card'>
                    <div className='RecruitmentProcess_card_icon'>
                      <Lottie options={Lottie_Goals} height={130} width={130} />
                    </div>
                    <div
                      className='horizontal_line'
                      style={{ visibility: 'hidden' }}
                    >
                      <div className='horizontal_line_inner'>
                        <hr />
                      </div>
                    </div>

                    <div className='step_count'>
                      <div className='step_count_number'>2</div>
                    </div>

                    <div
                      className='step_arrow_line'
                      style={{
                        left: '-20px',
                        marginTop: '44%',
                        transform: 'rotate(11deg)',
                      }}
                    >
                      <img
                        src={SecondLine}
                        alt=''
                        style={{ height: '247px' }}
                      />
                    </div>

                    <div
                      className='horizontal_line'
                      style={{ position: 'absolute', left: '48.5%' }}
                    >
                      <div
                        className='horizontal_line_inner'
                        style={{ marginTop: '30px' }}
                      >
                        <hr style={{ width: '8.2rem' }} />
                      </div>
                    </div>

                    <div className='RecruitmentProcess_card_wrapper'>
                      <div className='RecruitmentProcess_card_title'>
                        Phone Interview
                      </div>
                      <div className='RecruitmentProcess_card_text'>
                        We will have a telephonic interview and get to know
                        about you and your work profile.
                      </div>
                    </div>
                  </div>
                </div>
                {/* ---- */}
                <div className='interview_process_container'>
                  <div className='RecruitmentProcess_card'>
                    <div className='RecruitmentProcess_card_wrapper'>
                      <div className='RecruitmentProcess_card_title'>
                        Interview Schedule
                      </div>
                      <div className='RecruitmentProcess_card_text'>
                        We will invite you for an interview depending upon the
                        nature and expertise of the job role.
                      </div>
                    </div>

                    <div className='horizontal_line'>
                      <div className='horizontal_line_inner'>
                        <hr />
                      </div>
                    </div>

                    <div className='step_count'>
                      <div className='step_count_number'>3</div>
                    </div>

                    <div className='step_arrow_line'>
                      <img src={FirstLine} alt='' />
                    </div>

                    <div className='RecruitmentProcess_card_icon'>
                      <Lottie
                        options={Lottie_Interview}
                        height={150}
                        width={150}
                      />
                    </div>
                  </div>
                </div>
                {/* ---- */}
                <div className='interview_process_container'>
                  <div className='RecruitmentProcess_card'>
                    <div className='RecruitmentProcess_card_icon'>
                      <Lottie options={Lottie_Phone} height={150} width={150} />
                    </div>
                    <div
                      className='horizontal_line'
                      style={{ visibility: 'hidden' }}
                    >
                      <div className='horizontal_line_inner'>
                        <hr />
                      </div>
                    </div>

                    <div className='step_count'>
                      <div className='step_count_number'>4</div>
                    </div>

                    <div className='step_arrow_line'>
                      <img
                        src={SecondLine}
                        alt=''
                        style={{ visibility: 'hidden' }}
                      />
                    </div>

                    <div
                      className='horizontal_line'
                      style={{ position: 'absolute', left: '48.8%' }}
                    >
                      <div className='horizontal_line_inner'>
                        <hr style={{ width: '9.3rem' }} />
                      </div>
                    </div>

                    <div className='RecruitmentProcess_card_wrapper'>
                      <div className='RecruitmentProcess_card_title'>
                        Selection Status
                      </div>
                      <div className='RecruitmentProcess_card_text'>
                        After the final interview we will let you know whether
                        you have been selected or not.
                      </div>
                    </div>
                  </div>
                </div>
                {/* ---- */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecruitmentProcess;
