import React, { useState, useEffect } from 'react';
import './MarqueeSlider.css';
import Marquee from 'react-fast-marquee';
import axios from 'axios';

const MarqueeSlider = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // axios.get(`${process.env.REACT_APP_API_KEY}/stack`).then(res => {
    axios.get(`${process.env.REACT_APP_API_KEY}/our-team`).then(res => {
      setData(res.data);
    });
  }, []);
  return (
    <>
      {data &&
      data.filter(board => board.add_to_slider === false).length > 0 ? (
        <div className='marquee_slider_about_us'>
          <Marquee speed={200}>
            {data &&
              data
                .filter(slider => slider.add_to_slider === false)
                .sort((a, b) => a.order - b.order)
                .map((item, index) => (
                  <img
                    src={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${item.image}`}
                    alt=''
                    key={index}
                  />
                ))}
          </Marquee>
          <Marquee speed={200} direction='right'>
            {data &&
              data
                .filter(slider => slider.add_to_slider === false)
                .sort((a, b) => b.order - a.order)
                .map((item, index) => (
                  <img
                    src={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${item.image}`}
                    alt=''
                    key={index}
                  />
                ))}
          </Marquee>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default MarqueeSlider;
