import React from 'react';
import './TechnologyStack.css';
import ReactImg from '../../../../assets/Services/react.png';

import PostgreSQL from '../../../../assets/stacks/postgresql.png';
import MongoDB from '../../../../assets/stacks/mongodb.png';
import Mysql from '../../../../assets/stacks/mysql.png';
import Nextjs from '../../../../assets/stacks/nextjs.png';
import Nodejs from '../../../../assets/stacks/nodejs.png';

const TechnologyStack = () => {
  return (
    <>
      <div className='TechnologyStack_component'>
        <div className='TechnologyStack_wrapper container'>
          <div className='TechnologyStack_heading'>Technology Stack</div>

          <div className='TechnologyStack_subheading'>
            Technolgies We Use to Make Our <br /> Softwares!
          </div>

          <div className='TechnologyStack_development_tools'>
            <div className='TechnologyStack_development_tools_wrapper'>
              <div className='ant-row'>
                <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-8 ant--col-lg-4 ant-col-xl-4'>
                  <div className='development_tools_img'>
                    <img src={ReactImg} alt='' />
                  </div>
                </div>

                <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-8 ant--col-lg-4 ant-col-xl-4'>
                  <div className='development_tools_img'>
                    <img src={PostgreSQL} alt='' />
                  </div>
                </div>

                <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-8 ant--col-lg-4 ant-col-xl-4'>
                  <div className='development_tools_img'>
                    <img src={MongoDB} alt='' />
                  </div>
                </div>

                <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-8 ant--col-lg-4 ant-col-xl-4'>
                  <div className='development_tools_img'>
                    <img src={Mysql} alt='' />
                  </div>
                </div>

                <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-8 ant--col-lg-4 ant-col-xl-4'>
                  <div className='development_tools_img'>
                    <img src={Nextjs} alt='' />
                  </div>
                </div>

                <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-8 ant--col-lg-4 ant-col-xl-4'>
                  <div className='development_tools_img'>
                    <img src={Nodejs} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechnologyStack;
