import React from 'react';
import './TestimonialCard.css';
import { Rate } from 'antd';
import LeftQuote from '../../../../../assets/LandingPage/left.png';
import RightQuote from '../../../../../assets/LandingPage/right.png';

const TestimonialCard = props => {
  return (
    <>
      <div className='TestimonialCard'>
        <div className='TestimonialCard_wrapper container'>
          <div className='left_quote'>
            <img src={LeftQuote} alt='' />
          </div>
          <div className='right_quote'>
            {' '}
            <img src={RightQuote} alt='' />
          </div>
          <div className='testimonial_card_shape '>
            <div className='TestimonialCard_img'>
              <img src={props.image} alt='' />
            </div>
            <div className='TestimonialCard_title'>{props.name}</div>

            <div className='TestimonialCard_title_position'>
              {props.company}
            </div>

            <div className='TestimonialCard_text'>{props.description}</div>
            <div className='Testimonialcard_rating'>
              <Rate disabled defaultValue={5} />
            </div>
          </div>
          <div className='triangle-left-bottom'></div>
        </div>
      </div>
    </>
  );
};

export default TestimonialCard;
