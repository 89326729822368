import React from 'react';
import './ContactForm.css';
import { Link } from 'react-router-dom';
import CoreContactFrom from './CoreContactFrom';

const ContactForm = () => {
  return (
    <>
      <div id='ContactForm'>
        <div className='ContactForm_wrapper container'>
          <div className='ant-row'>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='contacts_contact_card '>
                <div className='contacts_contact_card_wrapper'>
                  <div className='have_a_project_heading'>Have a Project?</div>
                  <div className='have_a_project_subheading'>
                    We’d Love to Hear From You.
                  </div>
                  <div className='contact_text_heading'>Address</div>
                  <div className='contact_text_subheading'>
                    Radhe Radhe, Bhaktapur 44800, Nepal
                  </div>

                  <div className='contact_text_heading'>Phone</div>
                  <div className='contact_text_subheading'>01-5900970</div>

                  <div className='contact_text_heading'>Email</div>
                  <div className='contact_text_subheading'>info@aitc.ai</div>

                  <div className='career_text'>
                    Are you looking to join us? Feel free to drop us a note:
                    <span style={{ color: 'var(--primary-color)' }}>
                      {' '}
                      career@aitc.ai{' '}
                    </span>
                    <br />
                    <Link
                      to='/career'
                      style={{ color: 'var(--primary-color)' }}
                    >
                      {' '}
                      See all openings &#8594;
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <CoreContactFrom />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
