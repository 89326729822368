import React from 'react';
import './AboutusHero.css';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { MdDoubleArrow } from 'react-icons/md';

const { Title } = Typography;

const AboutusHero = () => {
  const scrollToSection = () => {
    scroller.scrollTo('MissionAndVison', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -70,
    });
  };

  // SearchJobs_career

  return (
    <>
      <div id='AboutusHero'>
        <div className='AboutusHero_wrapper '>
          <div className='AboutusHero_wrapper_text_content container'>
            <div className='hero_container_heading'>
              <Title level={1}>
                Connecting with creative peoples to bring an everlasting change.
              </Title>
            </div>

            <div className='hero_container_sub-heading'>
              <Title level={5}>
                {' '}
                We are a creative team of designers, developers, database
                engineers and project managers working together to build amazing
                products and services for end-users.
              </Title>
            </div>

            <div className='hero_container_buttons'>
              <Link to='/career'>
                <div className='hero-contact-btn'>Join Our Team</div>
              </Link>{' '}
            </div>
          </div>
        </div>

        <Link
          to='#'
          onClick={event => {
            // event.preventDefault();
            scrollToSection();
          }}
        >
          <div className='scroll_down_btn'>
            <MdDoubleArrow size={32} /> <br />
            Scroll Down
          </div>
        </Link>
      </div>
    </>
  );
};

export default AboutusHero;
