import React, { useState, useEffect } from 'react';
import './OurValues.css';
import axios from 'axios';

import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { Divider } from 'antd';

const OurValues = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/our-values-data/`).then(res => {
      setData(res.data);
    });
  }, []);

  return (
    <>
      <div className='OurValues_career'>
        <div className='OurValues_career_wrapper container'>
          <div className='OurValues_career_heading'>Our values</div>
          <Divider>
            <BiDotsHorizontalRounded />
          </Divider>
          <div className='ant-row'>
            {/* card starting */}
            {data &&
              data
                // .filter((a, b) => a.order - b.order)
                .map(e => (
                  <div
                    className='ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-8 ant-col-xl-8'
                    key={e.id}
                  >
                    <div className='OurValues_career_card'>
                      <div className='OurValues_career_card_wrapper'>
                        <div className='OurValues_career_card_title'>
                          {e.title}
                        </div>
                        <div className='OurValues_career_card_info'>
                          <p> {e.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurValues;
