import React from 'react';
import './CareerHero.css';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import CareerPeople from '../../../../assets/Career/career.json';
import { scroller } from 'react-scroll';
import Lottie from 'react-lottie';

const { Title } = Typography;

const CareerHero = () => {
  const scrollToSection = () => {
    scroller.scrollTo('SearchJobs_career', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -70,
    });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: CareerPeople,
    renderer: 'svg',
  };

  return (
    <>
      <div className='CareerHero'>
        <div className='CareerHero_wrapper container'>
          <div className='ant-row'>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='CareerHero_heading'>
                <h2
                  style={{ fontFamily: " 'Barlow', sans-serif !important" }}
                  className='text-[48px] leading-[58px] text-[#000000D9] font-semibold'
                >
                  Make the most out of your Skills.
                </h2>
              </div>

              <div className='hero_container_sub-heading'>
                <h3
                  style={{ fontFamily: " 'Barlow', sans-serif !important" }}
                  className='text-[48px] leading-[58px] text-[#000000D9] font-semibold'
                  level={5}
                >
                  Join a team where you can explore your skills and create{' '}
                  <br /> an Impact in the technology.
                </h3>
              </div>
              <div className='hero_container_buttons'>
                <Link
                  to='#'
                  onClick={event => {
                    // event.preventDefault();
                    scrollToSection();
                  }}
                >
                  <div className='hero-contact-btn'>View Openings &#8594;</div>
                </Link>
              </div>
            </div>

            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='CareerHero_banner_img'>
                {/* <img src={CareerPeople} alt='' /> */}
                <div id='hero_svgg'>
                  <Lottie
                    options={defaultOptions}
                    height={250}
                    width={'100%'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerHero;
