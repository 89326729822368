import React, { useState, useEffect } from 'react';
import './BlogPage.css';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { Skeleton } from 'antd';
import { Helmet } from 'react-helmet-async';

const BlogPage = () => {
  const [data, setData] = useState(null);
  const [allData, setAllData] = useState(null);
  const { id } = useParams();

  const bid = id.split('-').reverse()[0];

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/blogs/${bid}`).then(res => {
      setData(res.data);
    });
  }, [id]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/blogs`).then(res => {
      setAllData(res.data);
    });
  }, []);

  let response = data?.description;

  let regex = /(<([^>]+)>)/gi;
  let newString = response?.replace(regex, '');
  let firstParagraphWithoutHtml = newString?.substr(0, 120);

  return (
    <>
      {data && (
        <Helmet>
          <title data-react-helmet='true'>{data?.title}</title>
          <meta
            name='description'
            content={firstParagraphWithoutHtml}
            data-react-helmet='true'
          />
          <meta
            property='og:title'
            content={data?.title}
            data-react-helmet='true'
          />
          <meta
            property='og:description'
            content={firstParagraphWithoutHtml}
            data-react-helmet='true'
          />
          <meta
            property='og:image'
            content={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${data?.image}`}
            data-react-helmet='true'
          />
          <meta
            data-react-helmet='true'
            name='twitter:card'
            content='summary_large_image'
          />

          <meta
            data-react-helmet='true'
            name='twitter:image'
            content={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${data?.image}`}
          />
        </Helmet>
      )}
      <div id='BlogPage'>
        <div className='BlogPage_wrapper container'>
          <div className='ant-row'>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-15 ant-col-xl-15'>
              <div className='blog_breadcrumb'>
                <Link to='/'>Home</Link> / <Link to='/blog'>Blog</Link> /{' '}
                {data?.title}
              </div>
              <div className='blog_category_in_heading'>{data?.category}</div>

              <div className='BlogPage_heading'>{data?.title}</div>

              {data === null ? (
                <Skeleton active style={{ padding: '15px' }} />
              ) : (
                <>
                  <div className='BlogPage_heading_text'>
                    <p style={{ textTransform: 'capitalize' }}>
                      <Moment format='DD MMM, YYYY'>{data?.date}</Moment>
                    </p>
                  </div>
                  <div className='BlogPage_image'>
                    <div
                      dangerouslySetInnerHTML={{ __html: data?.description }}
                    />
                  </div>
                </>
              )}
            </div>

            <div
              className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-9 ant-col-xl-9'
              style={{ marginTop: '80px', marginBottom: '40px' }}
            >
              {/* next blog */}

              <div className='latest_blogs_heading'>
                <h2 style={{ fontWeight: 'bold', padding: '5px 10px' }}>
                  Other Blogs
                </h2>
              </div>

              {allData &&
                allData
                  .filter(
                    name => name.title !== undefined && name.id !== Number(id)
                  )
                  .slice(0, 6)
                  .sort((a, b) => b.date - a.date)
                  .map(item => (
                    <Link
                      to={
                        `/article/` +
                        item.title
                          .toLowerCase()
                          .match(/[a-z0-9\s-]+/g)
                          .map(x => x.trim().split(/\s+/).join('-'))
                          .join('')
                          .replace(/-and\b/g, '')
                          .replace(/ \([\s\S]*?\)/g, '') +
                        '-' +
                        item.id
                      }
                      key={item.id}
                    >
                      <div className='sidebar-blogs'>
                        <div className='blog-sidebar-img'>
                          <img
                            src={
                              `${process.env.REACT_APP_API_KEY_WITHOUT_V1}/` +
                                item.image || 'https://via.placeholder.com/150'
                            }
                            alt=''
                            height={130}
                            width={180}
                          />
                        </div>
                        <div className='card_text_card'>
                          <div className='blog-sidebar-category'>
                            {item.category}
                          </div>
                          <div className='blog-sidebar-date'>
                            {' '}
                            <Moment format='DD MMM, YYYY'>{item?.date}</Moment>
                          </div>

                          <div className='blog-sidebar-heading'>
                            {item.title.slice(0, 30)}...
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPage;
