import { Divider } from 'antd';
import React, { useState, useEffect } from 'react';
import './BenefitsAndPackage.css';
import axios from 'axios';

const BenefitsAndPackage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/benefits-and-packiages`)
      .then(res => {
        // axios.get(`http://localhost:3000/v1/benefits-and-packiages`).then(res => {
        setData(res.data);
      });
  }, []);

  return (
    <div>
      {data && data.length > 0 ? (
        <div id='BenefitsAndPackage'>
          <div className='BenefitsAndPackage_container'>
            <div className='BenefitsAndPackage_wrapper container'>
              <div className='BenefitsAndPackage_heading'>
                Benefits & Package
                <Divider />
              </div>

              <div className='ant-row'>
                {data &&
                  data.map((e, index) => (
                    <div
                      className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-8 ant-col-lg-6 ant-col-xl-6'
                      key={index}
                    >
                      <div className='benefits_and_package_card'>
                        <div className='benefits_and_package_card_img'>
                          <img
                            src={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${e.image}`}
                            // src={`http://localhost:3000/${e.image}`}
                            alt=''
                          />
                        </div>

                        <div className='benefits_and_package_card_text'>
                          {e.title}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default BenefitsAndPackage;
