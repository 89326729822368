import { Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
const ServiceDropDown = ({ data }) => {
  return (
    <Menu className=' w-full  border-t-[2px] border-[#FF6C14]   '>
      <div className='arrow-up'></div>
      <div className='w-full py-6 px-10 flex bg-[#ff770007] shadow-md justify-between '>
        <div className='md:hidden lg:block lg:w-[30%]  xl:w-[50%]'>
          <h1 className='mt-1 font-semibold  text-2xl uppercase font-rubik leading-3 '>
            Services
          </h1>
          <p className='text-[#525252] font-light 2xl:w-[300px] leading-[16px]'>
            We are a fully-service web development company that specializes in
            crating websites and web applications
          </p>
          <p className='mt-8'>Can’t find what you’re looking for?</p>
          <Link to={`/contact`}>
            <button className='px-5 rounded-[4px] py-2 text-white bg-[#FF6C14] '>
              ASK US
            </button>
          </Link>
        </div>
        {data && (
          <div className='w-full  flex justify-between'>
            <div className=' flex flex-col gap-5'>
              {data?.map((item, i) => {
                if (i < data.length / 2) {
                  return (
                    <Link
                      to={
                        `/technology/` +
                        item.title
                          .replace(/\s+/g, '-')
                          .toLowerCase()
                          .replace(/\//g, '-')
                      }
                      key={item._id}
                      className=''
                    >
                      <div className='w-full   hover:text-orange-500 flex sample cursor-pointer text-black gap-2'>
                        <div className='w-10 rounded-[4px] h-10 border flex justify-center items-center'>
                          <img
                            src={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${item?.titleImg}`}
                            alt='icons'
                            className='w-7 h-7 object-contain'
                          />
                        </div>
                        <div className=''>
                          <h1 className='font-semibold text-base text-inherit'>
                            {item?.title}
                          </h1>
                          <p className='text-xs leading-[1px]  text-[#525252]'>
                            {item?.shortName}
                          </p>
                        </div>
                      </div>
                    </Link>
                  );
                }
              })}
            </div>
            <div className='w-[50%] flex flex-col gap-5'>
              {data?.map((item, i) => {
                if (i > data.length / 2) {
                  return (
                    <Link
                      to={
                        `/technology/` +
                        item.title
                          .replace(/\s+/g, '-')
                          .toLowerCase()
                          .replace(/\//g, '-')
                      }
                      key={item._id}
                    >
                      <div className='w-full   hover:text-orange-500 flex sample cursor-pointer text-black gap-2'>
                        <div className='w-10 rounded-[4px] h-10 border flex justify-center items-center'>
                          <img
                            src={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${item?.titleImg}`}
                            alt='icons'
                            className='w-full h-full object-contain'
                          />
                        </div>
                        <div className=''>
                          <h1 className='font-semibold text-base text-inherit'>
                            {item?.title}
                          </h1>
                          <p className='text-xs leading-[1px] text-[#525252]'>
                            {item?.shortName}
                          </p>
                        </div>
                      </div>
                    </Link>
                  );
                }
              })}
            </div>
          </div>
        )}
      </div>
    </Menu>
  );
};

export default ServiceDropDown;
