import { Collapse, Space } from 'antd';
import React from 'react';
import './FAQ.css';

import { AiOutlinePlusCircle } from 'react-icons/ai';

const { Panel } = Collapse;
const FAQ = props => {
  return (
    <div>
      <Space direction='vertical'>
        {' '}
        <Collapse
          collapsible='header'
          defaultActiveKey={props.defaultActiveKey}
        >
          <Panel
            header={props.header}
            key={props.index}
            collapsible
            showArrow={false}
            extra={
              <AiOutlinePlusCircle style={{ color: 'var(--primary-color)' }} />
            }
          >
            <div dangerouslySetInnerHTML={{ __html: props.text }} />
          </Panel>
        </Collapse>
      </Space>
    </div>
  );
};

export default FAQ;
