import React from 'react';
import './WhyChooseUs.css';
import Vector from '../../../../assets/LandingPage/Vector.png';
import Macbook from '../../../../assets/LandingPage/MacBook.png';
import MacbookSlider from './MacbookSlider';

const WhyChooseUs = () => {
  return (
    <>
      <div className='why_choose_us_component'>
        <div className='landing_page_component container'>
          <div className='landing_page_component_wrapper container'>
            <div className='landing_page_component_title'>Why Choose Us?</div>
            <div className='landing_page_component_content'>
              <div className='ant-row'>
                <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-10 ant-col-lg-10 ant-col-xl-10'>
                  <div className='inside_macbook'>
                    <MacbookSlider />
                  </div>

                  <div className='macbook_pro'>
                    <img src={Macbook} alt='' />
                  </div>
                  <div className='bg_shape'>
                    <img src={Vector} alt='' />
                  </div>
                </div>
                <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-1 ant-col-lg-1 ant-col-xl-1'>
                  {' '}
                </div>

                <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-13 ant-col-lg-13 ant-col-xl-13'>
                  <div className='why_choose_us_sub_heading'>
                    We Create Creative Websites
                  </div>

                  <div className='why_choose_us_sub_text'>
                    AITC International is a web application development company
                    with over 1 years of history in the sector. We have the
                    capacity to address the development of apps of any
                    complexity and size, adapting to the needs of our clients.{' '}
                    <br /> <br /> Based on the type of the applications, we use
                    the most appropriate technology for the project. At AITC, we
                    help you in all the phases of developing your app, from the
                    initial conception and analysis phase of the app to its
                    publication in the different application stores. <br />{' '}
                    <br /> If you are looking for a web application development
                    company in Nepal, we are your ideal companion. We will help
                    you develop professional apps while employing the best
                    approaches.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyChooseUs;
