import React from 'react';
import './OurIndustries.css';
import Vector from '../../../../assets/LandingPage/Industries/RecttangleVector.svg';
import MainImage from '../../../../assets/LandingPage/Industries/image.jpg';

import { OurIndustriesData } from '../../../../Storage/OurIndustriesData';
import Flip from 'react-reveal/Flip';
import { Divider } from 'antd';

const OurIndustries = () => {
  return (
    <>
      <div id='OurIndustries'>
        <div className='OurIndustries_wrapper container'>
          <div className='OurIndustries_heading'>Industries We Serve</div>
          <Divider />

          <div className='ant-row' style={{ marginTop: '40px' }}>
            {' '}
            <div
              className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-16 ant-col-xl-16'
              // key={e.id}
            >
              <div className='ant-row'>
                {OurIndustriesData.map(e => (
                  <div
                    className='ant-col ant-col-xs-12 ant-col-sm-8  ant-col-md-8 ant-col-lg-6 ant-col-xl-6'
                    key={e.id}
                  >
                    <Flip left cascade duration={3000}>
                      <div className='OurIndustries_card'>
                        <div className='OurIndustries_card_img'>
                          <div className='OurIndustries_card_img_wrapper'>
                            <img src={e.iconUrl} alt='' />
                            <div className='OurIndustries_card_text'>
                              {e.title}
                            </div>
                          </div>

                          {/* <div className='OurIndustries_card_text'>{e.title}</div> */}

                          <div className='OurIndustries_card_vector'>
                            <img src={Vector} alt='' />
                          </div>
                        </div>
                      </div>
                    </Flip>
                  </div>
                ))}
              </div>
            </div>
            <div
              className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-8 ant-col-xl-8'
              // key={e.id}
            >
              <div className='OurIndustries_card_banner_wrapper'>
                <div className='OurIndustries_card_banner_text'>
                  <p>
                    We craft modern services and products based on the industry
                    type and ease them in their business.
                  </p>
                </div>
                <div className='OurIndustries_card_banner_img'>
                  <img src={MainImage} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurIndustries;
