import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AdditionalTechnologyDescription,
  FAQ,
  TechnologyDescription,
  TechnologyHeading,
} from '../../components/UI';
import './OurTechnologies.css';
import axios from 'axios';
import { Skeleton } from 'antd';
import { Helmet } from 'react-helmet-async';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { Divider } from 'antd';
const URL = process.env.REACT_APP_API_KEY_WITHOUT_V1;

const OurTechnologies = () => {
  const [data, setData] = useState(null);
  const [individualService, setIndividualService] = useState(null);
  const [individualFaqService, setIndividualFaqService] = useState(null);

  const { title } = useParams();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/stack`).then(res => {
      setData(res.data);
    });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/additional-services`)
      .then(res => {
        setIndividualService(res.data);
      });
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/service-faqs`).then(res => {
      setIndividualFaqService(res.data);
    });
  }, []);

  return (
    <>
      {data &&
        data
          .filter(
            filteredData =>
              filteredData.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase() === title
          )
          .map(e => (
            <Helmet key={e._id}>
              <title data-react-helmet='true'>
                {e.title} | AITC International
              </title>
              <meta
                name='description'
                content='AITC is an amazing IT company with technical and experienced professionals who have extensive experience in providing software and services on a global scale.'
                data-react-helmet='true'
              />
              <meta
                property='og:title'
                content={`${e.title} | AITC International`}
                data-react-helmet='true'
              />
              <meta
                property='og:description'
                content='AITC is an amazing IT company with technical and experienced professionals who have extensive experience in providing software and services on a global scale.'
                data-react-helmet='true'
              />
              <meta
                property='og:image'
                content='https://d1q13i93c33suk.cloudfront.net/aitcsocialog.jpg'
                data-react-helmet='true'
              />
              <meta name='twitter:card' content='summary_large_image' />

              <meta
                name='twitter:image'
                content='https://d1q13i93c33suk.cloudfront.net/aitcsocialog.jpg'
              />
              <link
                rel='canonical'
                href={`https://aitc.ai/technology/${e.title
                  .toLowerCase()
                  .replace(' ', '-')}`}
              />
            </Helmet>
          ))}

      {data === null ? (
        <div className='container'>
          <div
            className='loading-wrapper'
            style={{ width: '100%', height: '90vh', marginTop: '7%' }}
          >
            <Skeleton active />
            <Skeleton active style={{ marginTop: '7%' }} />
            <Skeleton active style={{ marginTop: '7%' }} />
          </div>
        </div>
      ) : (
        data
          .filter(
            filteredData =>
              filteredData.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase() === title
          )
          .map(e => (
            <div className='' key={e._id}>
              <TechnologyHeading
                key={e.id}
                title={e.title}
                first_title={e.title.split(' ')[0]}
                second_title={e.title.split(' ').slice(1)}
                subTitle={e.subTitle}
                bgImg={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${e.HeaderBackground}`}
                technologyIllustration={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${e.HeaderImg}`}
              />
              <TechnologyDescription
                key={e._id}
                first_title={e.title}
                second_title={e.second_title}
                description={e.description}
                bgImg={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${e.descriptionBackground}`}
                list={e.list}
              />
              <div className='additional-tech'>
                <div className='AdditionalTechnologyDescription ant-row '>
                  <div className='w-full mb-8 flex flex-col items-center'>
                    <div className='text-[#FF6C14] mt-20 font-bold uppercase text-2xl md:text-[40px]'>
                      What we offer?
                    </div>
                    <div className='mt-2 text-sm md:min-w-[450px] sm:text-base px-5 md:w-[35%]  text-center'>
                      Since 2019, we have been a trusted software development
                      company that specializes in assisting clients in
                      navigating the convoluted landscape of digital
                      transformation. With a strong focus on software
                      engineering, we have established ourselves as a reliable
                      partner for renowned brands.
                    </div>
                  </div>
                  <div className='AdditionalTechnologyDescription_wrapper container'>
                    {individualService &&
                      individualService
                        .filter(data => data.parentId === e._id)
                        .map(i => (
                          <AdditionalTechnologyDescription
                            title={i.title}
                            key={i._id}
                            description={i.description}
                            imagePlayer={i?.image}
                          />
                        ))}
                  </div>
                </div>
              </div>
              <div id='Service_faq'>
                <div className='Service_faq_wrapper container'>
                  <div className='Service_faq_heading'>
                    Frequently Asked Questions
                    <Divider>
                      <BiDotsHorizontalRounded />
                    </Divider>
                  </div>
                  {individualFaqService &&
                    individualFaqService
                      .filter(data => data.parentId === e._id)
                      .map(i => (
                        <>
                          <FAQ
                            key={i._id}
                            header={i.title}
                            text={i.description}
                            defaultActiveKey={[1]}
                          />
                        </>
                      ))}
                </div>
              </div>
            </div>
          ))
      )}
    </>
  );
};

export default OurTechnologies;
