import React, { useState, useEffect } from 'react';
import './Testimonial.css';
import TestimonialCard from './TestimonialCard/TestimonialCard';
import Slide from 'react-reveal/Slide';
import axios from 'axios';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import 'swiper/css/navigation';

import { Autoplay, Pagination } from 'swiper';
import { Divider } from 'antd';

const Testimonial = () => {
  const [data, setData] = useState(null);
  const [webSetting, setWebSetting] = useState(null);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/testimonial`).then(res => {
      setData(res.data);
    });
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/web-settings`).then(res => {
      setWebSetting(res.data);
    });
  }, []);

  return (
    <>
      <div id='Testimonial'>
        <div className='Testimonial_wrapper container'>
          <Slide left>
            <div className='landing_page_component_title'>Our Testimonials</div>
            <Divider />
            <div className='landing_page_component_description'>
              {webSetting === null
                ? webSetting
                : webSetting[0].homepage_our_testimonial_sub_heading}{' '}
            </div>
          </Slide>

          <div className='testimonail_section'>
            <Swiper
              pagination={true}
              loop={true}
              spaceBetween={30}
              slidesPerView={3}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: false,
              // }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                390: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
                552: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },

                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 43,
                },
              }}
              modules={[Autoplay, Pagination]}
              className='mySwiper container'
            >
              {data &&
                data.map(e => (
                  <SwiperSlide>
                    <TestimonialCard
                      key={e._id}
                      image={
                        process.env.REACT_APP_API_KEY_WITHOUT_V1 + '/' + e.image
                      }
                      name={e.name}
                      company={e.subTitle}
                      description={e.description}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
