import React from 'react';
import {
  CareerHero,
  CounterContainer,
  OurValues,
  RecruitmentProcess,
  SearchJobs,
  BenefitsAndPackage,
} from '../../components/UI';
import './Career.css';
import { Helmet } from 'react-helmet-async';

const Career = () => {
  return (
    <>
      <Helmet>
        <title data-react-helmet='true'>
          Career - AITC International | IT Company based in Bhaktapur
        </title>
        <meta
          name='description'
          content='Advance your career with our expert guidance & resources. Discover new opportunities, develop your skills, & reach your full potential. Contact @ aitc.ai'
          data-react-helmet='true'
        />
        <meta
          property='og:description'
          content='We offers wide range of IT services that helps to achive your business goals. From platform development to marketing we will grow togther. Contact Now!!'
          data-react-helmet='true'
        />

        <meta property='og:url' content='https://aitc.ai' />
        <meta property='og:type' content='website' />
        <meta
          data-react-helmet='true'
          property='og:title'
          content=' AITC International | Web, App & Software Development Company '
        />
        <meta
          data-react-helmet='true'
          property='og:description'
          content=' AITC International provides a wide range of IT services including web design, software development, digital marketing, mobile app development, and more. Contact us today to discuss your project! '
        />

        <meta
          property='og:image'
          content='https://d1q13i93c33suk.cloudfront.net/aitcsocialog.jpg'
        />
        <meta name='twitter:card' content='summary_large_image' />

        <meta
          name='twitter:image'
          content='https://d1q13i93c33suk.cloudfront.net/aitcsocialog.jpg'
        />
        <link rel='canonical' href='https://aitc.ai/career/' />
      </Helmet>
      <div className='career_page'>
        <CareerHero />
        <OurValues />
        <BenefitsAndPackage />
        <SearchJobs />
        <CounterContainer />
        <RecruitmentProcess />
      </div>
    </>
  );
};

export default Career;
