import React from 'react';
import {
  ServiceHero,
  ServicesRows,
  ServiceTab,
  TechnologyStack,
} from '../../components/UI';
import './Services.css';
import { Helmet } from 'react-helmet-async';

const Services = () => {
  return (
    <>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title data-react-helmet='true'>
          Software and IT services - AITC International{' '}
        </title>
        <meta
          data-react-helmet='true'
          name='title'
          content='Software and IT services - AITC International '
        />
        <meta
          data-react-helmet='true'
          name='description'
          content='Explore AITC International IT services AITC Provides Custom Software development, web development, digital marketing, SEO, AR/VR, IT support, and more. Grow your business with our expert solutions.'
        />

        <link rel='canonical' href='https://aitc.ai/services' />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://aitc.ai/services' />
        <meta
          data-react-helmet='true'
          property='og:title'
          content='Software and IT services - AITC International '
        />
        <meta
          data-react-helmet='true'
          property='og:description'
          content='Explore AITC International IT services AITC Provides Custom Software development, web development, digital marketing, SEO, AR/VR, IT support, and more. Grow your business with our expert solutions.'
        />
        <meta
          property='og:image'
          content='https://d1q13i93c33suk.cloudfront.net/aitcsocialog.jpg'
        />
        {/* <!-- Twitter --> */}
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://aitc.ai/services' />
        <meta
          property='twitter:title'
          content='Software and IT services - AITC International '
        />
        <meta
          data-react-helmet='true'
          property='twitter:description'
          content='Explore AITC International IT services AITC Provides Custom Software development, web development, digital marketing, SEO, AR/VR, IT support, and more. Grow your business with our expert solutions.'
        />
        <meta
          data-react-helmet='true'
          property='twitter:image'
          content='https://d1q13i93c33suk.cloudfront.net/aitcsocialog.jpg'
        />
      </Helmet>
      <ServiceHero />
      <ServiceTab />
      {/* <ServicesRows /> */}
      <TechnologyStack />
    </>
  );
};

export default Services;
