import React, { useState, useEffect } from 'react';
import { BlogCard } from '../../components/UI';
import './Blog.css';
import { Typography, Skeleton, Divider } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const { Title } = Typography;

const Blog = () => {
  const URL = process.env.REACT_APP_API_KEY_WITHOUT_V1;
  const [data, setData] = useState(null);
  const [loading] = useState(true);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/blogs/latest-one`).then(res => {
      // axios.get(`http://localhost:3000/v1/blogs/latest-one`).then(res => {
      setData(res.data);
    });
  }, []);

  return (
    <div id='Blog_page'>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title data-react-helmet='true'>
          Blogs & insights - AITC International{' '}
        </title>
        <meta
          data-react-helmet='true'
          name='title'
          content='Blogs & insights - AITC International'
        />
        <meta
          data-react-helmet='true'
          name='description'
          content="Get valuable insights on IT trends, industry updates, and digital marketing strategies from AITC's experts. Stay informed and make decisions for your business "
        />

        <link
          data-react-helmet='true'
          rel='canonical'
          href='https://aitc.ai/blog'
        />

        {/* <!-- Open Graph / Facebook --> */}
        <meta data-react-helmet='true' property='og:type' content='website' />
        <meta
          data-react-helmet='true'
          property='og:url'
          content='https://aitc.ai/blog'
        />
        <meta
          data-react-helmet='true'
          property='og:title'
          content=' Blogs & insights - AITC International'
        />
        <meta
          data-react-helmet='true'
          property='og:description'
          content="Get valuable insights on IT trends, industry updates, and digital marketing strategies from AITC's experts. Stay informed and make decisions for your business "
        />
        <meta
          data-react-helmet='true'
          property='og:image'
          content='https://d1q13i93c33suk.cloudfront.net/aitcsocialog.jpg'
        />

        {/* <!-- Twitter --> */}
        <meta
          data-react-helmet='true'
          property='twitter:card'
          content='summary_large_image'
        />
        <meta
          data-react-helmet='true'
          property='twitter:url'
          content='https://aitc.ai/blog'
        />
        <meta
          data-react-helmet='true'
          property='twitter:title'
          content='Blogs & insights - AITC International'
        />
        <meta
          data-react-helmet='true'
          property='twitter:description'
          content="Get valuable insights on IT trends, industry updates, and digital marketing strategies from AITC's experts. Stay informed and make decisions for your business "
        />
        <meta
          data-react-helmet='true'
          property='twitter:image'
          content='https://d1q13i93c33suk.cloudfront.net/aitcsocialog.jpg'
        />
      </Helmet>

      <div
        className='Blog_page_wrapper container'
        style={{ marginTop: '40px' }}
      >
        <div className='blog_page_banner_card '>
          <div className='blog_page_banner_card_wrapper'>
            <div className='ant-row'>
              {data === null ? (
                <Skeleton loading={loading} active />
              ) : (
                <>
                  <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-12 ant-col-xl-12'>
                    <Link
                      to={
                        `/article/` +
                        data.title
                          .toLowerCase()
                          .match(/[a-z0-9\s-]+/g)
                          .map(x => x.trim().split(/\s+/).join('-'))
                          .join('')
                          .replace(/-and\b/g, '')
                          .replace(/ \([\s\S]*?\)/g, '') +
                        '-' +
                        data.id
                      }

                      // {`/article/${data?.title
                      //   .replace(/\s+/g, '-')
                      //   .replace(/\./g, '')
                      //   .replace(/;/g, '')
                      //   .replace(/\//g, '-')
                      //   .toLowerCase()}-${data?.id}`}
                    >
                      <Title level={1}>
                        {data?.title}
                        <span style={{ color: 'var(--primary-color)' }}>
                          {' '}
                          {/* {data?.title.split(' ').pop()} */}
                        </span>
                      </Title>

                      <Title level={4}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data?.description.slice(0, 250) + ' ...',
                          }}
                        />
                      </Title>
                    </Link>

                    <div className='career_link'>
                      <Link
                        to={
                          `/article/` +
                          data.title
                            .toLowerCase()
                            .match(/[a-z0-9\s-]+/g)
                            .map(x => x.trim().split(/\s+/).join('-'))
                            .join('')
                            .replace(/-and\b/g, '')
                            .replace(/ \([\s\S]*?\)/g, '') +
                          '-' +
                          data.id
                        }
                      >
                        <div className='hero-contact-btn'>
                          Read more &#8594;
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-12 ant-col-xl-12'>
                    <img
                      src={
                        `${process.env.REACT_APP_API_KEY_WITHOUT_V1}/` +
                        data?.image
                      }
                      alt=''
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <Divider dashed />
      </div>
      <BlogCard />
    </div>
  );
};

export default Blog;
