import React from 'react';
import {
  AboutusHero,
  MissionAndVison,
  MarqueeSlider,
} from '../../components/UI';
import { CareerLink } from '../../components/UI/AboutusComponent';
import './AboutUs.css';
import { Helmet } from 'react-helmet-async';
import OurTeamSlider from '../../components/UI/AboutusComponent/OurTeam/OurTeamSlider/OurTeamSlider';

const AboutUs = () => {
  return (
    <div style={{ marginBottom: '25px' }}>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title data-react-helmet='true'>
          About AITC International | Top IT company in Nepal
        </title>
        <meta
          data-react-helmet='true'
          name='title'
          content=' About AITC International | Top IT company in Nepal'
        />
        <meta
          data-react-helmet='true'
          name='description'
          content='AITC International is leading IT company Provides IT services as well as Software development servivces to their clients. They Provides many other IT solutions like Web development, Digital marketing UIUX design QA testing and many more '
        />

        <link
          data-react-helmet='true'
          rel='canonical'
          href='https://aitc.ai/about'
        />

        {/* <!-- Open Graph / Facebook --> */}
        <meta data-react-helmet='true' property='og:type' content='website' />
        <meta
          data-react-helmet='true'
          property='og:url'
          content='https://aitc.ai/about'
        />
        <meta
          data-react-helmet='true'
          property='og:title'
          content=' About AITC International | Top IT company in Nepal'
        />
        <meta
          data-react-helmet='true'
          property='og:description'
          content='AITC International is leading IT company Provides IT services as well as Software development servivces to their clients. They Provides many other IT solutions like Web development, Digital marketing UIUX design QA testing and many more  '
        />
        <meta
          data-react-helmet='true'
          property='og:image'
          content='https://d1q13i93c33suk.cloudfront.net/aitcsocialog.jpg'
        />
        {/* <!-- Twitter --> */}
        <meta
          data-react-helmet='true'
          property='twitter:card'
          content='summary_large_image'
        />
        <meta
          data-react-helmet='true'
          property='twitter:url'
          content='https://aitc.ai/about'
        />
        <meta
          data-react-helmet='true'
          property='twitter:title'
          content=' About AITC International | Top IT company in Nepal'
        />
        <meta
          data-react-helmet='true'
          property='twitter:description'
          content=' AITC International is leading IT company Provides IT services as well as Software development servivces to their clients. They Provides many other IT solutions like Web development, Digital marketing UIUX design QA testing and many more '
        />
        <meta
          data-react-helmet='true'
          property='twitter:image'
          content='https://d1q13i93c33suk.cloudfront.net/aitcsocialog.jpg'
        />
      </Helmet>
      <AboutusHero />
      <MissionAndVison />
      <OurTeamSlider />
      <MarqueeSlider />
      <CareerLink />
    </div>
  );
};

export default AboutUs;
