import React, { useState } from 'react';
import './AdditionalTechnologyDescription.css';
import { Typography, Divider } from 'antd';

const { Title } = Typography;
const AdditionalTechnologyDescription = props => {
  const [initialReadmoreCount, setInitialReadmoreCount] = useState(350);
  const [showMoreText, setShowMoreText] = useState('Show more..');

  const onClickReadmore = () => {
    setInitialReadmoreCount(1000);
    setShowMoreText('');
  };

  return (
    // <div className='AdditionalTechnologyDescription ant-row'>
    //   <div className='AdditionalTechnologyDescription_wrapper container'>

    <div
      style={{ marginTop: 80 }}
      className='odd_and_even_seperator    additional_description_wrapper ant-row'
    >
      <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
        <div className='service_rows_and_column_text'>
          <div className='text-[#FF6C14] text-2xl md:text-[40px] font-bold uppercase '>
            {props.title}
          </div>

          <div className='mt-6 text-sm md:text-base'>
            {props.description.slice(0, initialReadmoreCount)}{' '}
            <span
              style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
              onClick={() => {
                onClickReadmore();
              }}
            >
              {props.description.length > initialReadmoreCount
                ? showMoreText
                : ''}
            </span>{' '}
          </div>
        </div>
      </div>
      <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
        <div className='service_rows_and_column_illustration  h-[300px]'>
          <img
            src={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${props.imagePlayer}`}
            alt=''
            className='h-full'
          />
        </div>
      </div>
      {/* <div className='container'>
        <Divider dashed />
      </div>{' '} */}
    </div>

    //     </div>
    // </div>
  );
};

export default AdditionalTechnologyDescription;
