import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Routess from './routes';
import { BackTop } from 'antd';
import { IoArrowUpOutline } from 'react-icons/io5';
import { Navbar, Footer } from './components/layout';
import ReactGA from 'react-ga';
import '@lottiefiles/lottie-player';
import Consultation from './components/UI/Consultation/Consultation';
import { Helmet } from 'react-helmet-async';
// test function,please pull it

let test = 'test';
const TRACKING_ID = 'G-RKDJYXPFZR';
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='canonical' href='https://aitc.ai' />
        <link
          rel='preconnect'
          href='https://fonts.gstatic.com'
          crossOrigin='anonymous'
        />
        <link
          href='https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700;800&family=Inter:wght@200;300;400&family=Rubik:ital,wght@0,600;0,700;1,600&display=swap'
          rel='stylesheet'
        />
      </Helmet>
      <BrowserRouter>
        <Navbar />

        <div className='App'>
          <Routess />
        </div>
        <BackTop>
          <IoArrowUpOutline size={32} />
        </BackTop>
        <Consultation />
        <Footer />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
