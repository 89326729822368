export { Home } from './Home';
export { OurWork } from './OurWork';
export { ContactUs } from './ContactUs';
export { AboutUs } from './AboutUs';
export { Services } from './Services';
export { OurTechnologies } from './OurTechnologies';
export { Career } from './Career';
export { ApplyJob } from './ApplyJob';
export { VacancyResult } from './VacancyResult';
export { Blog, BlogPage } from './Blog';
