import React from 'react';
import './TechnologyHeading.css';
import { Typography } from 'antd';
// import WebBanner from '../../../../assets/developmentTools/web_banner.png';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
const { Title } = Typography;
const TechnologyHeading = props => {
  const isMobile = useMediaQuery({
    query: '(max-width: 950px)',
  });
  const technologyHeaderStyle = {
    background: 'url(' + props.bgImg + ')',
    backgroundRepeat: 'no-repeat no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
  };

  // const player = document.querySelector('lottie-player');
  // player.addEventListener('rendered', e => {
  //   player.load(props.technologyIllustration);
  // });

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: props.technologyIllustration,
  //   renderer: 'svg',
  // };

  return (
    <>
      <div className='h-[534px] ' style={!isMobile?technologyHeaderStyle:{}}>
        <div className='TechnologyHeading_wrapper container'>
          <div className='ant-row'>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='TechnologyHeading_heading uppercase  mt-20 '>
                <Title level={1}>
                  <span style={{ color: 'var(--primary-color)' }}>
                    {props.first_title}
                  </span>{' '}
                  <br /> {props.second_title}
                </Title>
              </div>

              <div className=' font-light text-[#525252] sm:w-[450px] text-base font-barlow'>
                <p>{props.subTitle}</p>
              </div>
              <div className='hero_container_buttons'>
                <Link
                  to={`/contact/${props.title
                    .replace(/\s+/g, '-')
                    .toLowerCase()
                    .replace(/\//g, '-')}
                        `}
                >
                  <div className='hero-contact-btn'>Send Request</div>
                </Link>
              </div>
            </div>

            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='TechnologyHeading_banner_img'>
                {/* <img src={props.technologyIllustration} alt='' /> */}

                {/* <Lottie options={defaultOptions} height={400} width={'100%'} /> */}

                {/* <lottie-player
                  autoplay
                  loop
                  mode='normal'
                  src={props.technologyIllustration}
                  style={{ height: '400px' }}
                ></lottie-player> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechnologyHeading;
