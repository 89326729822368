import React from 'react';
import { Link } from 'react-router-dom';
import './Jobscard.css';
import Moment from 'react-moment';
const Jobscard = props => {
  return (
    <>
      <div className='Jobscard'>
        {' '}
        <Link to={props.link}>
          <div className='Jobscard_wrapper'>
            <div className='Jobscard_heading'>{props.title}</div>
            <div className='Jobscard_time'>
              {' '}
              <Moment format='DD MMM, YYYY'>{props.time}</Moment>
            </div>
            <div className='Jobscard_location'>{props.location}</div>
            <div className='Jobscard_more_details'>
              View Details &#8594;
            </div>{' '}
          </div>{' '}
        </Link>
      </div>
    </>
  );
};

export default Jobscard;
