import React from 'react';
import { Link } from 'react-router-dom';
import './OurServiceCard.css';
import Flip from 'react-reveal/Flip';
import Lottie from 'react-lottie';

const OurServiceCard = props => {
  return (
    <>
      <Flip top duration={1000}>
        <Link to={props.link}>
          <div className='our_service_card'>
            <div className='our_service_card_wrapper'>
              <div className='our_service_icon'>
                {/* <img src={props.Service_icon} alt={props.imgAlt} /> */}

                {/* <Lottie options={defaultOptions} height={110} width={110} /> */}
                <lottie-player
                  autoplay
                  loop
                  mode='normal'
                  src={props.animationData}
                  style={{ width: '110px' }}
                ></lottie-player>
              </div>
              <div className='our_service_title'>
                <h2>{props.Service_title}</h2>
              </div>

              <div className='our_service_description'>
                <p>{props.description}</p>
              </div>

              <div className='bottom_line_draw'></div>
            </div>
          </div>
        </Link>
      </Flip>
    </>
  );
};

export default OurServiceCard;
