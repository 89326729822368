import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import Logo from '../../../assets/logo.png';
import './MobileNavbar.css';
import { Link, useNavigate } from 'react-router-dom';
import { Divide as Hamburger } from 'hamburger-react';
import axios from 'axios';
import { Menu } from 'antd';
import {
  AppstoreOutlined,
  MessageOutlined,
  HomeOutlined,
  CloudServerOutlined,
  PicRightOutlined,
} from '@ant-design/icons';
// import { DevelopmentStack } from '../../../Storage/DevelopmentStack';

const { SubMenu } = Menu;

// submenu keys of first level
const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

const MobileNavbar = () => {
  const [visible, setVisible] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState(null);

  const onClose = () => {
    setVisible(false);
    setToggle(false);
  };

  useEffect(() => {}, [toggle]);

  // ----------------

  const [openKeys, setOpenKeys] = React.useState(['sub1']);

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/stack`).then(res => {
      setData(res.data);
    });
  }, []);

  const navigate = useNavigate();

  return (
    <div id='Mobile_Navbar'>
      <div className='mobile_nav_wrapper container'>
        <div className='mobile_nav_contents'>
          <div className='logo'>
            <Link to='/'>
              <img alt={'logo'} src={Logo} />
            </Link>
          </div>

          <div className='mobile_nav_menu_icon'>
            <div className='menu_icon'>
              <Hamburger toggled={visible} toggle={setVisible} />
            </div>
          </div>
        </div>
        <Drawer
          className='mobile_drawer'
          title={
            <>
          
              <img alt={'logo'} src={Logo} />{' '}
            </>
          }
          placement='left'
          onClose={onClose}
          closable={false}
          mask={false}
          visible={visible}
          width='260'
        >
          <Menu mode='inline' openKeys={openKeys} onOpenChange={onOpenChange}>
            <Menu.Item
              key='home'
              icon={<HomeOutlined />}
              onClick={() => {
                navigate('/', { replace: true });
                onClose();
              }}
            >
              Home
            </Menu.Item>

            <Menu.Item
              key='about'
              icon={<HomeOutlined />}
              onClick={() => {
                navigate('/about', { replace: true });
                onClose();
              }}
            >
              About us
            </Menu.Item>

            <SubMenu key='service' icon={<AppstoreOutlined />} title='Service'>
              <Menu.Item
                key='5'
                onClick={() => {
                  navigate('/services', { replace: true });
                  onClose();
                }}
              >
                Services
              </Menu.Item>
              <SubMenu key='sub3' title='Our Services'>
                {data &&
                  data
                    .filter(
                      filteredData => filteredData.allow_in_menu === 'true'
                    )
                    .map((e, index) => (
                      <Menu.Item
                        key={index + 100}
                        onClick={() => {
                          navigate(
                            `/technology/` +
                              e.title
                                .replace(/\s+/g, '-')
                                .toLowerCase()
                                .replace(/\//g, '-'),
                            { replace: true }
                          );
                          onClose();
                        }}
                      >
                        {e.title}
                      </Menu.Item>
                    ))}
              </SubMenu>
            </SubMenu>
            <Menu.Item
              key='Career'
              icon={<CloudServerOutlined />}
              onClick={() => {
                navigate('/Career', { replace: true });
                onClose();
              }}
            >
              Career
            </Menu.Item>
            <Menu.Item
              key='Blog'
              icon={<PicRightOutlined />}
              onClick={() => {
                navigate('/blog', { replace: true });
                onClose();
              }}
            >
              Blog
            </Menu.Item>

            <Menu.Item
              key='contact'
              icon={<MessageOutlined />}
              onClick={() => {
                navigate('/contact', { replace: true });
                onClose();
              }}
            >
              Let's Connect
            </Menu.Item>
          </Menu>
        </Drawer>
      </div>
    </div>
  );
};

export default MobileNavbar;
