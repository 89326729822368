import React, { useState, useEffect } from 'react';
import { Card, Divider, Tabs, Typography } from 'antd';
import './OurOffering.css';
import axios from 'axios';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const OurOffering = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/our-offerings`).then(res => {
      setData(res.data);
    });
  }, []);

  const { TabPane } = Tabs;

  const { Title } = Typography;
  return (
    <div>
      {data && data.length > 0 ? (
        <div id='OurOffering'>
          <div className='OurOffering_wrapper container'>
            <div className='Consultation_heading'>Explore Our Offering</div>
            <Divider />
            <div className='offering_tab'>
              <Tabs defaultActiveKey='1' tabPosition={'left'} animated={false}>
                {data &&
                  data.map((e, index) => (
                    <TabPane tab={e.title} key={index}>
                      <div className='offering_tab_content'>
                        <div className='offering_tab_content_heading'>
                          <Title
                            level={3}
                            style={{ marginTop: '20px', marginBottom: '10px' }}
                          >
                            {' '}
                            {e.title}
                          </Title>
                        </div>

                        <div className='offering_tab_content_text'>
                          <div
                            dangerouslySetInnerHTML={{ __html: e.description }}
                          />
                        </div>

                        {/* <div className='ant-row'>
                        <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12 list_offer'>
                          <ArrowRightOutlined /> Software consulting
                        </div>
                        <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12 list_offer'>
                          <ArrowRightOutlined /> Custom software development
                        </div>
                      </div> */}
                      </div>
                    </TabPane>
                  ))}
              </Tabs>
            </div>

            <div className='explore_offering_tab_for_mobile'>
              <Swiper
                pagination={true}
                modules={[Pagination]}
                className='mySwiper'
                spaceBetween={20}
              >
                {data &&
                  data.map((e, index) => (
                    <SwiperSlide key={index}>
                      <Card>
                        <div className='offering_tab_card_for_mobile'>
                          <div className='offering_tab_card_heading_for_mobile'>
                            <h3>{e.title}</h3>
                            <Divider />
                          </div>

                          <div className='offering_tab_content_text'>
                            <div className='ant-row'>
                              <div className='offering_tab_content_text'>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: e.description,
                                  }}
                                />
                              </div>
                            </div>

                            {/* <span>FRONT END</span>
                        <div className='ant-row'>
                          <div className='ant-col ant-col-xs-24 ant-col-sm-8  ant-col-md-6 ant-col-lg-4 ant-col-xl-4 '>
                            <img src={Reactjs} alt='' />
                          </div>
                        </div> */}
                            <br />
                          </div>
                        </div>
                      </Card>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default OurOffering;
