import React, { useState, useEffect } from 'react';
import './JobDetails.css';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

import { useParams } from 'react-router-dom';
import ApplyJobForm from './ApplyJobForm';
import { Divider } from 'antd';

const JobDetails = () => {
  const [data, setData] = useState(null);
  const { name } = useParams();

  // const ContactUrl = 'http://localhost:3000/v1/vacancy-data/';

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/vacancy`).then(res => {
      // axios.get(`http://localhost:3000/v1/vacancy`).then(res => {
      setData(res.data);
    });
  }, []);

  return (
    <>
      {data &&
        data.map(e => (
          <Helmet>
            <title>{e.title}</title>
            <meta
              name='description'
              content='AITC is an amazing IT company with technical and experienced professionals who have extensive experience in providing software and services on a global scale.'
              data-react-helmet='true'
            />

            <meta
              property='og:image'
              content='https://aitc.ai/logo.png'
              data-react-helmet='true'
            />
          </Helmet>
        ))}

      <div id='JobDetails'>
        <div className='job_details_wrapper'>
          <div className='job_detais_heading'>
            <div className='job_detais_heading_wrapper'>
              {data &&
                data
                  .filter(
                    e => e.title.replace(/[\W]/g, '-').toLowerCase() === name
                  )
                  .map(vt => <h1>{vt.title}</h1>)}

              <p>Full time - Radhe Radhe, Bhaktapur</p>
            </div>
          </div>

          {/* <div className='jobheading container' style={{ paddingTop: '25px' }}>
            {' '}
            {data &&
              data
                .filter(
                  e => e.title.replace(/[\W]/g, '-').toLowerCase() === name
                )
                .map(vt => <h1>{vt.title}</h1>)}
          </div> */}

       

          <div className='job_description container'>
            <div className='ant-row'>
              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-14 ant-col-xl-14'>
                {/* --------------------- */}

                <div id='Job_Qualifications'>
                  <div className='Job_Qualifications_container'>
                    <div className='Job_Qualifications_wrapper '>
                      {/* <div className='ant-row'>
                        {[...Array(6)].map((elementInArray, index) => (
                          <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-12 ant-col-lg-8 ant-col-xl-8'>
                            <div className='benefits_and_package_card'>
                              <div className='benefits_and_package_card_label'>
                                Job Category
                              </div>

                              <div className='benefits_and_package_card_text'>
                                Competitive Salaries
                              </div>
                            </div>
                          </div>
                        ))}
                      </div> */}

                      {/* ------ */}
                      <div className='ant-row'>
                        {data &&
                          data
                            .filter(
                              e =>
                                e.title.replace(/[\W]/g, '-').toLowerCase() ===
                                name
                            )
                            .map(vt => (
                              <>
                                <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-12 ant-col-lg-8 ant-col-xl-8'>
                                  <div className='benefits_and_package_card'>
                                    <div className='benefits_and_package_card_label'>
                                      Job Category
                                    </div>

                                    <div className='benefits_and_package_card_text'>
                                      {vt.job_category}
                                    </div>
                                  </div>
                                </div>
                                <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-12 ant-col-lg-8 ant-col-xl-8'>
                                  <div className='benefits_and_package_card'>
                                    <div className='benefits_and_package_card_label'>
                                      No. of Vacancy
                                    </div>

                                    <div className='benefits_and_package_card_text'>
                                      {vt.no_of_vacancy}
                                    </div>
                                  </div>
                                </div>
                                <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-12 ant-col-lg-8 ant-col-xl-8'>
                                  <div className='benefits_and_package_card'>
                                    <div className='benefits_and_package_card_label'>
                                      Employment Type
                                    </div>

                                    <div className='benefits_and_package_card_text'>
                                      {vt.job_type}
                                    </div>
                                  </div>
                                </div>
                                <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-12 ant-col-lg-8 ant-col-xl-8'>
                                  <div className='benefits_and_package_card'>
                                    <div className='benefits_and_package_card_label'>
                                      Job Location
                                    </div>

                                    <div className='benefits_and_package_card_text'>
                                      {vt.job_location}
                                    </div>
                                  </div>
                                </div>
                                <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-12 ant-col-lg-8 ant-col-xl-8'>
                                  <div className='benefits_and_package_card'>
                                    <div className='benefits_and_package_card_label'>
                                      Level
                                    </div>

                                    <div className='benefits_and_package_card_text'>
                                      {vt.job_level}
                                    </div>
                                  </div>
                                </div>
                                <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-12 ant-col-lg-8 ant-col-xl-8'>
                                  <div className='benefits_and_package_card'>
                                    <div className='benefits_and_package_card_label'>
                                      Offered Salary
                                    </div>

                                    <div className='benefits_and_package_card_text'>
                                      {vt.offered_salary}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                      </div>
                      {/* --- */}
                    </div>
                  </div>
                </div>

                {/* --------------------- */}

                <div className='about_job_heading'>
                  About Job
                  <Divider />
                </div>

                {data &&
                  data
                    .filter(
                      e => e.title.replace(/[\W]/g, '-').toLowerCase() === name
                    )
                    .map(vt => (
                      <div
                        className='hero_container_sub-heading ant-typography'
                        dangerouslySetInnerHTML={{
                          __html: vt.data,
                        }}
                      />
                    ))}

                {data &&
                  data
                    .filter(
                      e => e.title.replace(/[\W]/g, '-').toLowerCase() === name
                    )
                    .map(vt => (
                      <span
                        style={{
                          color: 'var(--primary-color)',
                          padding: '10px 0',
                        }}
                      >
                        Date : {vt.date.slice(0, 10)}
                      </span>
                    ))}
              </div>
              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-1 ant-col-xl-1'>
                {' '}
              </div>

              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-9 ant-col-xl-9'>
                <ApplyJobForm />
              </div>
            </div>

            <div className='about_our_company'>
              <div className='ant-row'>
                <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-14 ant-col-xl-14'>
                  <h2> About AITC International :</h2>{' '}
                  <p>
                    With high-quality and efficient services, we meet the
                    expectations of our customers and regard ourselves as
                    industry leaders. Our mission is to provide our customers
                    with high-quality and affordable software development and IT
                    consulting services. We believe that providing employees
                    with a decent working environment in a technological
                    environment can enable the company to achieve the highest
                    level of efficiency. 𝐀𝐈𝐓𝐂 strives to understand customer
                    needs and provide end-to-end solutions that comply with the
                    latest technology. We are looking for people who not only
                    have strong technical skills but also show sincere
                    commitment to their jobs, customers, and colleagues. Strong
                    business relationships are the foundation of good customer
                    service.
                  </p>
                </div>
                <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-1 ant-col-xl-1'></div>

                <div
                  className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-9 ant-col-xl-9'
                  style={{ marginTop: '50px' }}
                >
                  <iframe
                    src={
                      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28267.081674587447!2d85.4324841408067!3d27.674486754083166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1be1b16919ed%3A0x16e2361a5fa7e2db!2sAmazing%20IT%20Centre%20Private%20Limited%20-%20AITC!5e0!3m2!1sen!2snp!4v1653026895265!5m2!1sen!2snp'
                    }
                    width={'100%'}
                    height={'300'}
                    style={{ border: '0' }}
                    allowfullscreen=''
                    loading='lazy'
                    referrerpolicy='no-referrer-when-downgrade'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetails;
