export const JobsList = [
  {
    id: 1,
    title: 'Wordpress Developer',
    time: 'Part Time',
    location: 'Kathmandu',
  },
  {
    id: 2,
    title: 'NodeJS Developer',
    time: 'Full Time',
    location: 'Bhaktapur',
  },
  {
    id: 3,
    title: 'Bhagawan Developer',
    time: 'Full Time',
    location: 'Kathmandu',
  },
  {
    id: 4,
    title: 'Wordpress Developer',
    time: 'Full Time',
    location: 'Kathmandu',
  },
  {
    id: 5,
    title: 'Unity Game Developer',
    time: 'Full Time',
    location: 'Kathmandu',
  },
  {
    id: 6,
    title: 'Wordpress Developer',
    time: 'Full Time',
    location: 'Kathmandu',
  },
  {
    id: 7,
    title: 'Wordpress Developer',
    time: 'Part Time',
    location: 'Bhaktapur',
  },
];
