import React from 'react';
import { JobDetails } from '../../components/UI';
import './ApplyJob.css';
import { Helmet } from 'react-helmet-async';

const ApplyJob = () => {
  return (
    <>
      <JobDetails />
    </>
  );
};

export default ApplyJob;
