import React from 'react';
import './404Page.css';
import Lottie from 'react-lottie';
import PageNotFound from '../../../assets/404/error-404.json';

const NotFound = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: PageNotFound,
    renderer: 'svg',
  };

  return (
    <>
      <div id='NotFound'>
        <div className='NotFound-wrapper'>
          <Lottie options={defaultOptions} height={400} width={'100%'} />
        </div>
      </div>
    </>
  );
};

export default NotFound;
