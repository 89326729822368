import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ServiceCard from './ServiceCard/ServiceCard';
import './ServiceTab.css';
import axios from 'axios';

import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper';
const ServiceTab = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/stack`).then(res => {
      setData(res.data);
    });
  }, []);

  let bGcolor = ['#95AFFE', '#FE95C4', '#FE9595'];

  return (
    <>
      <div className='ServiceTab_component'>
        <div className='ServiceTab_wrapper container'>
          <div className='ServiceTab_heading'>Services</div>

          <div className='ServiceTab_subheading'>
            <p>
              Services that we provide and have an years of <br /> Experience to
              deliver Quality products.
            </p>
          </div>

          <div className='ServiceTab_slider'>
            <Swiper
              spaceBetween={30}
              slidesPerView={4}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                390: {
                  slidesPerView: 1.3,
                  spaceBetween: 40,
                },
                552: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },

                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className='mySwiper'
            >
              {data &&
                data.map(e => (
                  <SwiperSlide key={e._id}>
                    <ServiceCard
                      HeaderImg={e.HomeJsonIllustration}
                      title={e.title}
                      link={
                        `/technology/` +
                        e.title
                          .replace(/\s+/g, '-')
                          .toLowerCase()
                          .replace(/\//g, '-')
                      }
                      bg={bGcolor[Math.floor(Math.random() * bGcolor.length)]}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceTab;
