import React, { useState, useEffect } from 'react';
import './RNP.css';
import Marquee from 'react-fast-marquee';
import axios from 'axios';
import { Divider, Popover } from 'antd';

const RNP = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // axios.get(`${process.env.REACT_APP_API_KEY}/stack`).then(res => {
    axios.get(`${process.env.REACT_APP_API_KEY}/our-partners`).then(res => {
      // axios.get(`http://localhost:3000/v1/our-partners`).then(res => {
      setData(res.data);
    });
  }, []);

  return (
    <div>
      {data && data.length > 0 ? (
        <div id='RNP'>
          <div className='RNP_wrapper container'>
            <div className='RNP_heading'>Recognitions and Partnerships</div>
            <Divider />
            <Marquee
              gradient={false}
              speed={100}
              pauseOnHover={true}
              pauseOnClick={true}
            >
              {data &&
                data
                  .sort((a, b) => a.order - b.order)
                  .map((item, index) => (
                    <Popover
                      animation='0'
                      content={<>{item.title}</>}
                      placement='bottom'
                      key={index}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${item.image}`}
                        width={150}
                        alt=''
                        className='object-contain'
                      />
                    </Popover>
                  ))}
            </Marquee>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default RNP;
