import React from 'react';
import './ServiceHero.css';
import Fade from 'react-reveal/Fade';
import { Button, Typography, Input } from 'antd';
import ServiceSVG from '../../../../assets/Services/service-illustration.json';
import Lottie from 'react-lottie';

const { Title } = Typography;

const ServiceHero = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ServiceSVG,
    renderer: 'svg',
  };
  return (
    <>
      <div id='Service_Hero_container'>
        <div className='hero_container_wrapper container'>
          <div className='ant-row' style={{ alignItems: 'center' }}>
            <Fade bottom cascade>
              <div
                className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'
                style={{ margin: '30px 0' }}
              >
                <div className='hero_container_heading'>
                  <Title level={1}>
                    <span style={{ color: 'var(--primary-color)' }}>
                      Professional
                    </span>{' '}
                    Web <br /> Development Services
                  </Title>
                </div>
                <div className='hero_container_sub-heading'>
                  <Title level={5}>
                    {' '}
                    Remember us for High Quality Services
                  </Title>
                </div>

                {/* <div className='hero_container_buttons'>
                  <Link to='/'>
                    <div className='hero-contact-btn'>Let's connect</div>
                  </Link>
                </div> */}
                <div className='Service_Hero_container_email_form'>
                  {/* <input type='email' />
                  <Button type='primary'>Get Started</Button> */}
                  <Input.Group compact>
                    <Input
                      style={{ width: 'calc(62% - 0px)' }}
                      placeholder='Email address'
                    />
                    <Button type='primary'>Get Started</Button>
                  </Input.Group>
                </div>
              </div>
            </Fade>

            <Fade top cascade>
              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
                <div id='hero_svg'>
                  <Lottie
                    options={defaultOptions}
                    height={550}
                    width={'100%'}
                  />
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceHero;
