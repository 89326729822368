import './BlogCard.css';
import React, { useState, useEffect } from 'react';
import { Typography, Skeleton, Card, Row, Col, Divider } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { ArrowRightOutlined } from '@ant-design/icons';
import { BiDotsHorizontalRounded } from 'react-icons/bi';

const { Title } = Typography;
const HomePageBlog = () => {
  const [data, setData] = useState(null);
  const [loading] = useState(true);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/blogs`).then(res => {
      // axios.get(`http://localhost:3000/v1/blogs/`).then(res => {
      setData(res.data);
    });
  }, []);

  return (
    <div id='Blog'>
      <div className='service_rows_and_column'>
        <div className='service_rows_and_column_wrapper container'>
          <div className='blog_heading'>Featured Blogs</div>

          <Divider>
            <BiDotsHorizontalRounded />
          </Divider>

          {data === null ? (
            <>
              <Row>
                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <Card style={{ width: 350, marginTop: 16 }}>
                    <Skeleton loading={loading} active></Skeleton>{' '}
                  </Card>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <Card style={{ width: 350, marginTop: 16 }}>
                    <Skeleton loading={loading} active></Skeleton>{' '}
                  </Card>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <Card style={{ width: 350, marginTop: 16 }}>
                    <Skeleton loading={loading} active></Skeleton>{' '}
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div className='blog_card'>
                <div className='ant-row'>
                  {' '}
                  {data &&
                    data

                      .sort((a, b) => b.id - a.id)
                      .filter(name => name.title !== undefined)

                      .slice(0, 3)
                      .map(e => (
                        <div
                          className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-8 ant-col-lg-8 ant-col-xl-8'
                          key={e._id}
                        >
                          <div className='individual_blog_card'>
                            <Link
                              to={
                                `/article/` +
                                e.title
                                  .toLowerCase()
                                  .match(/[a-z0-9\s-]+/g)
                                  .map(x => x.trim().split(/\s+/).join('-'))
                                  .join('')
                                  .replace(/-and\b/g, '')
                                  .replace(/ \([\s\S]*?\)/g, '') +
                                '-' +
                                e.id
                              }
                            >
                              <div className='blog_card_img'>
                                <img
                                  src={
                                    `${process.env.REACT_APP_API_KEY_WITHOUT_V1}/` +
                                      e.image ||
                                    'https://via.placeholder.com/150'
                                  }
                                  alt=''
                                />
                              </div>
                              <div
                                className='card-category'
                                style={{ marginTop: '10px' }}
                              >
                                {e.category}
                              </div>

                              <div
                                className='blog_card_wrapper'
                                style={{ marginTop: '10px' }}
                              >
                                <Title level={3}>
                                  {e.title.length < 40
                                    ? e.title
                                    : e.title.slice(0, 40) + '...'}
                                </Title>
                              </div>

                              {/* <div className='card-desc'>
                                <p>
                                  {e.description.length < 150 ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: e?.description,
                                      }}
                                    />
                                  ) : (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          e?.description.slice(0, 150) + '...',
                                      }}
                                    />
                                  )}
                                </p>
                              </div> */}

                              <div className='card_date_category'>
                                <div className='card_date'>
                                  Date :{' '}
                                  <Moment format='DD MMM, YYYY'>
                                    {e?.date}
                                  </Moment>{' '}
                                </div>

                                <div className='card-category-div'>
                                  {/* <div className='card_category'>
                                    {e.category}
                                  </div> */}
                                  <ArrowRightOutlined />
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePageBlog;
