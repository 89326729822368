import React, { useEffect, useState } from 'react';
import './Consultation.css';
import { PhoneOutlined, MailOutlined } from '@ant-design/icons';

import { Button, Divider, Form, Input, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const Consultation = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({}); // To disable submit button at the beginning.

  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <div>
      <div id='Consultation'>
        <div className='Consultation_wrapper container'>
          <div className='Consultation_heading'>Need a Consultation?</div>

          <Divider />
          <div className='Consultation_subheading'>
            Drop us a line! We are here to answer your questions 24/7.
          </div>

          <div className='ant-row'>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-18 ant-col-xl-18'>
              <Form form={form} name='horizontal_login' layout='inline'>
                <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                  <Form.Item
                    name='name'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your full name!',
                      },
                    ]}
                  >
                    <Input placeholder='Full Name' />
                  </Form.Item>
                </div>

                <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                  <Form.Item
                    name='company_name'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your company name!',
                      },
                    ]}
                  >
                    <Input placeholder='Company Name' />
                  </Form.Item>
                </div>

                <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                  <Form.Item
                    name='email'
                    rules={[
                      {
                        type: 'email',
                        required: true,
                        message: 'Please input your work email!',
                      },
                    ]}
                  >
                    <Input placeholder='Work Email' />
                  </Form.Item>
                </div>

                <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                  <Form.Item
                    name='phone'
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your phone!',
                      },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject();
                          }
                          if (isNaN(value)) {
                            return Promise.reject('Phone has to be a number.');
                          }
                          if (value.length < 10) {
                            return Promise.reject(
                              "Phone code can't be less than 10 digits"
                            );
                          }
                          if (value.length > 10) {
                            return Promise.reject(
                              "Phone code can't be more than 10 digits"
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input placeholder='Phone' />
                  </Form.Item>
                </div>

                <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-24 ant-col-xl-24'>
                  <Form.Item
                    name='message'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your message!',
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder='How can we help you?'
                      rows={4}
                    />
                  </Form.Item>
                </div>

                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      type='primary'
                      htmlType='submit'
                      size='large'
                      disabled={
                        !form.isFieldsTouched(true) ||
                        !!form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    >
                      DISCUSS MY NEEDS
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </div>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-6 ant-col-xl-6'>
              <div className='Consultation_info' style={{ marginTop: '10px' }}>
                <div className='Consultation_info_heading'>
                  <Title level={3} style={{ marginBottom: '0' }}>
                    Our contact details
                  </Title>
                </div>
                <span>
                  <PhoneOutlined style={{ transform: 'rotateY(-180deg)' }} />{' '}
                  <a href='tel:015900970'>01-5900970</a>
                </span>
                <br />
                <span style={{ marginTop: '15px' }}>
                  <MailOutlined />
                  <a href='mailto:info@aitc.ai'>info@aitc.ai</a>
                </span>

                <Title
                  level={3}
                  style={{ marginTop: '20px', marginBottom: '0' }}
                >
                  Join our team
                </Title>
                <Link to='/career'>
                  CHECK OUR OPEN VACANCIES <span>&#8594;</span>{' '}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consultation;
