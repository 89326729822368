import React from 'react';
import { Helmet } from 'react-helmet-async';

import {
  ContactForm,
  ContactMap,
  ContactPageHeaderImg,
} from '../../components/UI/ContactComponents';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title data-react-helmet='true'>
          Contact - AITC International | Best Software company in Nepal
        </title>
        <meta
          data-react-helmet='true'
          name='title'
          content=' Contact - AITC International | Best Software company in Nepal'
        />
        <meta
          data-react-helmet='true'
          name='description'
          content=' AITC International provides a wide range of IT services including web design, custom software development, digital marketing, mobile app development, and more. Contact us today to discuss your project! '
        />
        <link rel='canonical' href='https://aitc.ai/contact' />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://aitc.ai/contact' />
        <meta
          data-react-helmet='true'
          property='og:title'
          content='Contact - AITC International | Best Software company in Nepal'
        />
        <meta
          data-react-helmet='true'
          property='og:description'
          content=' AITC International provides a wide range of IT services including web design, custom software development, digital marketing, mobile app development, and more. Contact us today to discuss your project! '
        />
        <meta
          property='og:image'
          content='https://d1q13i93c33suk.cloudfront.net/aitcsocialog.jpg'
        />
        {/* <!-- Twitter --> */}
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://aitc.ai/contact ' />
        <meta
          data-react-helmet='true'
          property='twitter:title'
          content='Contact - AITC International | Best Software company in Nepal'
        />
        <meta
          data-react-helmet='true'
          property='twitter:description'
          content=' AITC International provides a wide range of IT services including web design, custom software development, digital marketing, mobile app development, and more. Contact us today to discuss your project! '
        />
        <meta
          property='twitter:image'
          content='https://d1q13i93c33suk.cloudfront.net/aitcsocialog.jpg'
        />
      </Helmet>
      <ContactPageHeaderImg />
      <ContactForm />
      <ContactMap />
    </>
  );
};

export default ContactUs;
