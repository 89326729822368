import Hospital from '../assets/LandingPage/Industries/hospital.png';
import Store from '../assets/LandingPage/Industries/Stores.png';
import Corporates from '../assets/LandingPage/Industries/Corporates.png';
import Education from '../assets/LandingPage/Industries/education-institutions.png';
import Manifacturing from '../assets/LandingPage/Industries/manufacturing-units.png';
import Hotels from '../assets/LandingPage/Industries/hotels.png';
import Construction from '../assets/LandingPage/Industries/construction.png';
import Marketing from '../assets/LandingPage/Industries/social-media.png';

export const OurIndustriesData = [
  {
    id: 1,
    iconUrl: Hospital,
    title: 'Requirement Analysis',
  },
  {
    id: 2,
    iconUrl: Store,
    title: 'Retail',
  },
  {
    id: 3,
    iconUrl: Corporates,
    title: 'Corporates',
  },
  {
    id: 4,
    iconUrl: Education,
    title: 'Education Institutions',
  },
  {
    id: 5,
    iconUrl: Manifacturing,
    title: 'Manufacturing Units',
  },
  {
    id: 6,
    iconUrl: Hotels,
    title: 'Hotels & Resorts',
  },
  {
    id: 7,
    iconUrl: Construction,
    title: 'Construction Industry',
  },
  {
    id: 8,
    iconUrl: Marketing,
    title: 'Marketing and Advertisement ',
  },
];
