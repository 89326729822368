import React from 'react';
import './ContactMap.css';

const ContactMap = () => {
  return (
    <>
      <div id='ContactMap'>
        <div className='ContactMap_wrapper container'>
          <iframe
            title='my-web'
            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14133.272538445775!2d85.3979723!3d27.6765606!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x16e2361a5fa7e2db!2sAmazing%20IT%20Centre%20Private%20Limited%20-%20AITC!5e0!3m2!1sen!2snp!4v1648098934469!5m2!1sen!2snp'
            width='100%'
            height='450'
            style={{ border: 0 }}
            allowfullscreen=''
            loading='lazy'
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default ContactMap;
